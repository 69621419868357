import React from "react";
import HelmetComponent from "./HelmetComponent";
import Header from "./Header";

function TermsAndConditions() {
  return (
    <div className="">
      <Header />
      <div
        className="cosmetic-pd-wrapper"
        style={{
          color: "#000",
          maxWidth: "900px",
          paddingTop: "96px",
          paddingInline: "1rem",
          margin: " 0 auto 20px",
        }}
      >
        <HelmetComponent
          title="Terms And Conditions | Teledental"
          name="Terms And Conditions"
          content="Welcome to Teledental. We try our best to provide good dental
                information for people seeking dental feedback or suggestions.
                Our goal is to provide good dental information that will
                hopefully provide useful dental suggestions for individuals."
        />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h1>Terms & Conditions</h1>
        </p>
        <p>
          <strong>TERMS AND CONDITIONS - Terms of Use of Teledental:</strong>
        </p>

        <p>
          <strong>
            TELEDENTAL ONLINE, LOCAL TELEDENTAL OFFICE LISTINGS & TELEDENTAL
            MARKETING ONLINE with us at Teledental.com.
          </strong>
        </p>
        <p>
          Welcome to Teledental. We try our best to provide good dental
          information for people seeking dental feedback or suggestions. Our
          goal is to provide good dental information that will hopefully provide
          useful dental suggestions for individuals. All people using our site
          (we will repeat this several times) will need to use good common sense
          and do their own due diligence. We do Not guarantee any dental results
          and Do Not guarantee that all dental information will be useful or
          accurate. Each dentist has their own view of what is the best course
          of action or the best dental treatment plan for any given patient. We
          believe Teledental is the future of dentistry. As technology gets
          better, we will be able to provide even more features. With any online
          site, there can always be errors or mistakes done. Hence, any dental
          information or suggestion should be used only as a suggestion. Our
          goal is to help people get better dental information.
        </p>

        <p>
          <u>
            We provide only dental suggestions and recommend all our users to
            seek a full dental exam and dental imaging x-rays at a local dental
            office to get a more clear and better understanding of the type of
            dental care treatment they require. Furthermore, any dentist or
            dental office referred to - you as a patient need to do your own due
            diligence. Any dental treatment received needs to approved by you
            and the dental office. Teledental has nothing to do with any kind of
            dental treatment or dental service. Hence, Teledental is not liable
            in any way for any dental service, costs or dental treatment
            provided at any dental office. Like any search engine, we have only
            done an introduction to the dental business - and hope that both
            parties are able to mutually benefit.
          </u>
        </p>
        <p>
          ANY LIVE DENTAL VIDEO CONSULTATION IS SUBJECTIVE AND ALL PATIENTS ARE
          ADVISED TO GET FURTHER IN-OFFICE FULL DENTAL EXAMINATION. MANY DENTAL
          ILLNESSES WILL NEED IN-OFFICE DENTAL IMAGING X-RAYS. WITH TELEDENTAL
          CONSULTATIONS, THE DENTIST CAN NOT SEE THE PATIENT IN PERSON. IT CAN
          PROVIDE GENERAL DENTAL INFORMATION. ANY SUGGESTIONS, ARE JUST THAT - A
          DENTAL SUGGESTIONS.
        </p>
        <p>
          WE RECOMMEND YOU USE OUR TELDENTAL SERVICES WISELY. LIKE ANY ONLINE
          SERVICE, IF FEEL SOMETHING IS NOT RIGHT - CAN STOP USING AND LET US
          KNOW.
        </p>
        <p>
          TELEDENTAL.COM CONNECTS DENTISTS AND PATIENTS - MANY OF THE DENTISTS
          AND DENTAL OFFICES ARE NOT EMPLOYEES OF TELEDENTAL.COM. DENTISTRY CAN
          BE SUBJECTIVE. WE CAN NOT GUARANTEE AND DO NOT PROVIDE AN ASSURANCE
          THAT WHAT YOU HEAR WILL BE USEFUL.
        </p>
        <p>
          THOSE WANTING FULL GUARANTEE - SHOULD NOT USE OUR SERVICES. WE
          GENERALLY DO NOT PROVIDE REFUNDS FOR ANY PAYMENT MADE DIRECTLY TO US.
          FURTHERMORE, THE DENTAL OFFICES WE REFER TO ARE NOT CONTROLLED BY US.
          TELEDENTAL.COM TAKES NO RESPONSIBILITY ANY DENTAL SERVICES PROVIDED BY
          ANY DENTIST OR DENTAL OFFICE. TELEDENTAL.COM WILL NOT PAY ANY FEES FOR
          ANY DENTAL TREATMENT BY ANY DENTISTS.
        </p>
        <p>
          ANY USER OF TELEDENTAL.COM OR TELEDENTAL APPS UNDERSTANDS THAT
          TELEDENTAL IS IN NO WAY RESPONSIBLE FOR ANY DENTAL TREATMENT, ANY
          DENTAL FEES AT ANY DENTAL OFFICE OR ANY SORT OF DIAGNOSIS. IF PROVIDED
          ANY REFERRAL TO ANY LOCAL DENTIST, THE PATIENT IS RESPONSIBLE TO DO
          ALL DUE DILIGENCES. ANY DENTAL TREATMENT PLAN IS THE PATIENT'S
          RESPONSIBILITY. THOSE WHO DO NOT ACCEPT THESE TERMS AND CONDITIONS,
          SHOULD NOT USE TELEDENTAL.COM OR ANY OF OUR TELEDENTAL SERVICES.
          FURTHERMORE, IF ANY MEDICATIONS ARE PRESCRIBED - THE PATIENT IS
          RESPONSIBLE FOR THEIR OWN WELL BEING. WITH ANTIBIOTICS, SOMETIMES
          PEOPLE ARE ALLERGIC. IF ALLERGIC OR HAVE ANY SIDE-EFFECTS, SHOULD STOP
          TAKING AND SEE A DOCTOR OR DENTIST IN PERSON AS SOON AS POSSIBLE.
        </p>
        <p>
          <u>
            BY USING OUR SITE, YOU ACCEPT AND AGREE TO THESE TERMS OF USE AND
            OUR PRIVACY POLICY.
          </u>
        </p>
        <p>
          Teledental has helped many, many people with various dental questions
          or needs. Our goal is to strive to be the best. We do allow a certain
          amount of anonymity, for those who rightfully do not want to share
          their exact identity with us. We will answer your dental question,
          even if only using an alias or nickname. We do expect people to use
          their own good judgment. We do try at Teledental to keep private as
          much as can your personal information and try to provide useful dental
          information. Anytime using an app or website, you as an individual
          should know there is never 100% security or lapses. Hence, we do not
          want your very personal information - that do not want anyone to ever
          know. Understand, that by using an app or website, there are certain
          risks as far as personal information.
        </p>
        <p>
          <u>
            ALL DENTAL INFORMATION OR SUGGESTIONS ON TELEDENTAL, ARE ONLY THAT
            -- SUGGESTIONS. ALL INDIVIDUALS USING TELEDENTAL ARE RESPONSIBLE FOR
            THEIR OWN DECISIONS. OUR GOAL IS TO PROVIDE INFORMATION, THAT
            HOPEFULLY WILL BE DENTALLY BENEFICIAL TO YOU. IN CASE OF A MEDICAL
            EMERGENCY, DENTAL EMERGENCY OR LIFE-THREATENING EMERGENCY, YOU
            SHOULD VISIT A LOCAL DENTIST, DOCTOR OR HOSPITAL AS SOON AS CAN.
            AGAIN, WE ADVISE ALL TO USE THEIR OWN JUDGEMENT AS TO WHAT IS BEST
            FOR THEM AND THEIR FAMILIES.
          </u>
        </p>
        <p>
          Teledental, Teledental.com.com, Teledental, Inc., Teledental App in
          ios and Teledental App in Android or TELEDENTAL - Hereon termed as
          “Teledental” or "Teledental.com" – BY USING THIS SITE, AND ANY OF THE
          OTHER “Teledental.com” SITES, YOU AGREE TO HOLD TELEDENTAL.COM
          HARMLESS AND ARE BOUND BY THESE TERMS AND CONDITIONS.{" "}
          <strong>
            BY USING OUR SITE, YOU ACCEPT OUR TERMS AND CONDITIONS.
          </strong>{" "}
          We hope you enjoy our sites and we are always looking for ways to
          improve and grow – striving to be the best, with your help.
          Teledental.com provides its service, including online Services such as
          directory system, coupons, chatroom, discussion forums, content,
          reviews, and any other media services/marketing services – termed as
          Services (the “Services”) to be subject to the following Terms of Use.
          These Terms of Use may be updated from time to time without notice to
          you. You can review the most current version of these Terms of Use at
          any time by visiting www.Teledental.com. TERMS AND CONDITIONS may be
          changed without notice. In addition, when using particular sites under
          the Teledental.com umbrella. We will be changing these terms and
          conditions without notice. Please come back here to see any changes
          being made. IF YOU DO NOT WISH TO ACCEPT OUR TERMS AND CONDITIONS, AND
          PRIVACY POLICY ON OUR SITE - THEN YOU CAN NOT USE OUR SERVICES. OUR
          TERMS AND CONDITIONS CAN OR MAY CHANGE WITHOUT NOTICE.
        </p>
        <p>
          <strong>
            PLEASE USE COMMON SENSE WHEN USING OUR SITE. WE ARE NOT A
            REPLACEMENT FOR YOUR LOCAL DENTIST. FAR FROM IT, WE LOOK TO WORK
            WITH LOCAL DENTISTS. TELEDENTAL IS MEANT TO PROVIDE MORE DENTAL
            INFORMATION TO OUR USERS, TO HOPEFULLY HELP THEM MAKE A MORE
            INFORMED DECISION. WE ONLY OFFER DENTAL INSIGHTS AND DENTAL
            SUGGESTIONS. AFTER THAT, THE USER WILL NEED TO MAKE THEIR OWN
            DECISION. OUR MISSION IS HELPING PEOPLE WITH THEIR DENTAL CARE. ANY
            DENTIST OR DENTAL OFFICE REFERRED BY TELEDENTAL, IS RESPONSIBLE FOR
            THEIR OWN DENTAL SERVICE. TELEDENTAL IS IN NO WAY RESPONSIBLE FOR
            ANY FEES, BILLS OR SERVICES PROVIDED TO ANY PERSON USING OUR SITE AT
            ANY DENTAL OFFICE. BEFORE HAVING ANY DENTAL SERVICE PROVIDED BY ANY
            DENTAL OFFICE, SHOULD MAKE SURE YOU UNDERSTAND WHAT YOU WILL BE
            PAYING, AS YOU WILL BE RESPONSIBLE FOR ALL FEES AND ALL SERVICES
            PROVIDED TO YOU.
          </strong>
        </p>
        <p>
          The following terms and conditions constitute an agreement between you
          and Teledental, Inc. (“Teledental” “we,” or “us”), the operator of
          Teledental.com (the “Site”) and related websites, applications,
          services and mobile applications provided by Teledental and on/in
          which these Terms of Use are posted or referenced (collectively, the
          “Services”). These Terms of Use (the “Terms of Use”), together with
          our Privacy Policy, Acceptable Use Policy and the Additional Terms
          Policy. which are of this agreement - which governs your use of the
          Services, whether or not you have created an account. You must agree
          to and accept all of the terms of this Agreement, or you don’t have
          the right to use our Teledental Services on this site, our apps or
          affiliated sites. WE RESERVE THE RIGHT TO CHANGE THE TERMS AND PRIVACY
          WITHOUT NOTICE - WE USE COMMON INDUSTRY WIDE STANDARDS AND MORE.
        </p>
        <strong>
          <p>
            ANY PERSON WITH ANY KIND OF DENTAL OR MEDICAL EMERGENCY SHOULD SEEK
            OR BE SEEN AT A DENTAL OFFICE BY A DENTIST OR MEDICAL OFFICE OR
            HOSPITAL. ALL USERS ARE EXPECTED TO USE THEIR OWN JUDGEMENT AND
            COMMON SENSE. WE CAN NOT ENSURE IMMEDIATE DENTAL CARE TO ANY USER OF
            OUR SITE. ALL USERS ARE EXPECTED TO DO THEIR OWN DUE DILIGENCE.
          </p>
        </strong>
        <p>
          ALL USERS OF THIS SITE SHOULD USE THIS SITE WITH COMMON SENSE. OUR
          GOAL IS TO HAVE THE BEST SITE THAT HELPS PEOPLE ALL OVER. WE HAVE MANY
          THOUSANDS OF USERS WHO SEEM TO BE HAPPY AT WHAT WE HAVE SUGGESTED TO
          THEM. THOUGH, ERRORS ON OUR SITE DO OCCUR TIME TO TIME. ANY DENTAL
          EMERGENCY NEEDS TO BE SEEN AT A ER HOSPITAL OR DENTAL OFFICE NEAR YOU
          AS SOON AS POSSIBLE. WITHOUT A FULL DENTAL IN OFFICE EXAM AND XRAYS,
          OUR CONSULT IS MEANT ONLY AS SUPPLEMENTAL INFORMATION THAT MAY OR MAY
          NOT BE ACCURATE. WE DO NOT ENDORSE ANY DENTISTS OR DENTAL VIEWS, OR
          DENTAL OFFICE(S) CONSULTATION, DENTAL ARTICLE, DENTAL SUGGESTION,
          DENTIST TREATMENT SUGGESTION OR DENTIST CONSULTATION OR DENTIST
          TREATMENT PLAN POSTED ON HERE. EVERYTHING STATED HERE SHOULD BE
          VERIFIED AND DO THOROUGH IN DENTAL OFFICE EXAMINATION AND DENTAL XRAYS
          TO KNOW EXACTLY WHAT IS GOING ON. INFORMATION ON THIS SITE PROVIDED TO
          THE USER IS MEANT AS SUPPLEMENTAL - AND MAY OR MAY NOT BE ACCURATE, AS
          WE CAN NOT SEE FULL MEDICAL INFORMATION / BACKGROUND OF USER AND WE DO
          NOT TAKE ALL THE REQUIRED XRAYS AND WE DO NOT DO IN PERSON, A THOROUGH
          DENTAL EXAM. HENCE, ERRORS MAY OCCER - THOUGH WE TRY OUR BEST TO BE
          HELPFUL AND TRY TO PROVIDE USEFUL INFORMATION. WE HAVE 3RD PARTY
          DENTAL OFFICES IN VARIOUS LOCATIONS THAT USE OUR SERVICE TO HELP OUR
          USERS. ALL USERS ACCEPT THAT WE ONLY HAVE PARTIAL INFORMATION - WE DO
          NOT HAVE FULL MEDICAL HISTORY, DO NOT DO IN-PERSON THOROUGH ORAL EXAM
          AND DO NOT HAVE THE ABILITY TO TAKE IN-PERSON DENTAL X-RAYS. WE DO NOT
          IN ANY WAY ENDORSE OR ARE RESPONSIBLE FOR THEIR TREATMENT PLAN OR HOW
          THEY TREAT THE USER. HENCE, ANY USER CONNECTED TO A DENTAL OFFICE OR
          DENTIST ON TELEDENTAL.COM - NEEDS TO DO THEIR OWN DUE DILIGENCE. WE IN
          NO WAY ENDORSE OR KNOW THE TREATMENT PLAN THEY WILL BE PROPOSING AND
          DO NOT KNOW DETAILS OF THE DENTIST(S) SKILL SET. WE AT TELEDENTAL.COM
          TAKE NO RESPONSIBILITY ON ANY TREATMENT OR SERVICE PROVIDED BY ANY
          DENTIST OR DENTAL OFFICE. WE DO NOT ENDORSE ANY DENTIST OR DENTAL
          OFFICE - THEY USE OUR SERVICE, BUT WE HAVE NO WAY OF CHECKING HOW GOOD
          THEY ARE OR WHAT TREATMENT PLAN THEY PROPOSE OR HOW THEY CHARGE THE
          PATIENT, ETC. HENCE, ANY USER OF OUR SITE UNDERSTANDS THIS FULLY AND
          IS ASSUMED TO HAVE COMMON SENSE. PLEASE DO NOT SHARE ANY INFORMATION
          ON THIS SITE, THAT YOU DO NOT WANT TO BE ONLINE. ANY ONLINE SITE, CAN
          BE HACKED - AS HARD AS WE TRY TO NOT LET THAT HAPPEN. WE SHARE YOUR
          INFORMATION TO DENTISTS IN YOUR LOCAL AREA, SO THEY CAN HELP YOU. WE
          MOSTLY LIMIT ACCESS TO DENTISTS AND DENTAL OFFICES IN OUR NETWORK. OUR
          GOAL IS TO HELP THE USER HAVE THEIR DENTAL QUESTION ANSWERED AND HELP
          THEM FIND DENTAL SERVICE. YOU AS PATIENT ALWAYS HAVE FULL CONTROL
          WHETHER WANT TO SEE A DENTIST OR DENTAL OFFICE, OR NOT. WE ENCOURAGE
          ALL OUR USERS TO USE COMMON SENSE AND TO SEE WHAT IS THEIR BEST
          OPTION.
        </p>
        <p>
          WE HOPE WE CAN HELP AND TO PROVIDE SOME OPTIONS FOR YOUR DENTAL CARE
          NEEDS. ALL USERS SHOULD DO THEIR OWN DISSEMINATION OF ANY SUGGESTIONS
          OR ANSWERS THEY ARE GIVEN WITH US. WE @ TELEDENTAL DO NOT CLAIM
          ACCURACY OF ANY INFORMATION GIVEN ON THIS SITE. OUR GOAL IS TO PROVIDE
          AS GOOD INFORMATION AS POSSIBLE, BUT INACCURACIES AND DIFFERING OF
          OPINIONS IS ALWAYS THE CASE. WE TRY TO HAVE GOOD DENTISTS ON OUR LIST,
          WE DO NOT ENDORSE OR VERIFY THE SKILL SET OF ANY DENTISTS OR DENTAL
          PRACTICES WE LIST. ALL OF OUR USERS SHOULD DO THEIR OWN DUE DILIGENCE.
          ALL THOSE TAKING MEDICATIONS SHOULD DEFINITELY CHECK WITH THEIR
          PHYSICIANS FIRST. MEDICATIONS CAN HAVE SIDE-EFFECTS SUCH AS ALLERGIES,
          ETC. WE DO USE SOME Ai / ARTIFICIAL INTELLIGENCE - LIKE ANY SOFTWARE,
          ERRORS OR MIS-STATEMENTS CAN BE MADE WITH THIS SOFTWARE. PLEASE DOUBLE
          CHECK ALL INFORMATION - ANY INFORMATION IS ONLY A SUGGESTION & NEEDS
          TO BE VERIFIED WITH A LICENSED DENTIST. WE CURRENTLY DO NOT ASK FOR
          ANY PAYMENT BY THE USER OR PATIENT ON OUR SITE, FOR ANSWERING DENTAL
          QUESTION(S), ETC. IF FEEL OUR SITE IS BEING HACKED OR MISUSED, PLEASE
          LET US KNOW. WE TRY OUR BEST TO WATCH WHAT IS HAPPENING ON
          TELEDENTAL.COM
        </p>
        <p>
          ARBITRATION NOTICE AND CLASS ACTION WAIVER: BY USING OUR SITE OR
          SERVICES, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED
          BY BINDING, INDIVIDUAL ARBITRATION AND THAT YOU WAIVE YOUR RIGHT TO
          PARTICIPATE IN A CLASS OR COLLECTIVE ACTION LAWSUIT, OR CLASS OR
          COLLECTIVE ARBITRATION.
        </p>
        <p>
          <strong>
            OUR TERMS AND CONDITIONS MAY CHANGE WITHOUT NOTICE. WE RESERVE ALL
            RIGHTS NOT EXPRESSLY STATED HERE. ALL TERMS ARE SUBJECT TO CHANGE
            WITHOUT NOTICE. OUR GOAL IS TO HAVE THE GOLD STANDARD OF PRIVACY,
            CUSTOMER AND BUSINESS SERVICE. ON OCCASION WE MAY HAVE INACCURATE
            INFORMATION – PLEASE CHECK ALL DENTAL INFORMATION FOR ACCURACY WITH
            A DENTIST / DENTAL PROFESSIONAL AT A DENTAL OFFICE. ALL SUGGESTIONS
            SHOULD BE FULLY REVIEWED WITH A DENTIST AT A DENTAL OFFICE. OUR GOAL
            IS TO PROVIDE SOME INFORMATION AND TO CONNECT PATIENTS WITH A
            DENTIST. WE DO NOT MAKE ANY REPRESENTATION AND DO NOT FULLY KNOW ALL
            THE FACTS. WE WANT TO PROVIDE INFORMATION THAT WE HOPE HELPS, THOUGH
            IT SHOULD ALWAYS BE FOLLOWED WITH A VISIT AND CONSULTATION WITH A
            LOCAL DENTIST AT A DENTAL OFFICE.
          </strong>
        </p>
        <p>
          <strong>
            <u>
              USING TELEDENTAL IS A CHOICE. ANY TELEDENTAL TELEDENTAL USER
              ACCEPTS TELEDENTAL.COM FULL TERMS OF USE AND PRIVACY POLICY -
              TELEDENTAL OR TELEDENTISTRY VIDEO CONSULTATION
            </u>
            : WE CAN NOT CONTROL WHAT ANY DENTIST OR DENTAL STAFF OR PERSON ON
            TELEDENTAL.COM STATES. PLEASE DO YOUR OWN DUE DILIGENCE. WE
            RECOMMEND GOING TO YOUR LOCAL DENTAL OFFICE, DENTAL CLINIC, DOCTOR
            OR HOSPITAL IN CASE OF A EMERGENCY. PLEASE NOTIFY US IF FEEL
            SOMETHING NOT RIGHT. WE CAN NOT GUARANTEE THAT ALL INQUIRIES WILL BE
            ANSWERED IMMEDIATELY. WE DO NOT GENERALLY REFUND ANY TELEDENTAL
            CONSULTATION. ONCE OUR PROFESSIONAL PROVIDES SERVICE - THAT IS ALL
            WE CAN DO. IF FEEL SOMEHOW SOMETHING WAS NOT CORRECT OR DID NOT
            RECEIVE SERVICE, WE WILL TRY TO ACCOMMODATE YOU. PLEASE DO NOT USE
            TELEDENTAL JUST TO TRY IT OUT. IF PAY ANY MONEY FOR THIS SERVICE,
            THEN THERE ARE NO REFUNDS - UNLESS SOMETHING WAS WRONG OR DID NOT
            RECEIVE SERVICE.
          </strong>
        </p>
        <p>
          <strong>
            ANY FEES PAID TO GET A TELEDENTAL CONSULT IS GENERALLY
            NON-REFUNDABLE. OUR CHARGES ARE FAIR AND WE HOPE YOU APPRECIATE YOUR
            CONSULT. WE CAN NOT GUARANTEE OUR CONSULTATION WILL BE EFFECTIVE OR
            WHAT YOU WANT TO HEAR. IF FEEL THERE IS SOME SORT OF WRONGFUL CHARGE
            - YOU CAN CONTACT US AT
            <a href="mailto:service@teledental.com">
              {" "}
              SERVICE@TELEDENTAL.COM
            </a>{" "}
            - WE CAN NOT GUARANTEE THERE WILL BE A PROMPT RESPONSE - BUT WE WILL
            TRY OUR BEST TO GET TO YOUR MESSAGE. IF DO NOT HEAR BACK FROM US
            WITHIN 48 HOURS, YOU MAY NEED TO EMAIL US ONE MORE TIME.
          </strong>
        </p>
        <p>
          <strong>
            ALL TELEDENTAL CONSULTATION DONE IS MEANT AS SUGGESTIONS, SINCE WE
            DO NOT HAVE FULL ACCESS TO YOUR DENTAL X-RAYS & CAN NOT DO A FULL
            INTRA-ORAL EXAM WITH AN EXPLORER OF YOUR TEETH. ANY PAYMENT MADE IS
            NON-REFUNDABLE. OUR GOAL IS TO HELP & PROVIDE USEFUL INFORMATION.
            FURTHERMORE, ANY USER ACCEPTS ALL OF THE TERMS AND CONDITIONS WHEN
            RECEIVING A TELEMEDICINE OR TELEDENTAL CONSULT THROUGH ANY OF THE
            TELEDENTAL, INC DEVICES OR TECHNOLOGIES. THIS INCLUDES TELEDENTAL
            APP(S), TELEDENTAL SERVICE ON DENTISTS WEBSITES OR TELEDENTAL.COM.
            PLEASE REVIEW THE PRIVACY POLICY TO FURTHER UNDERSTAND YOUR RIGHTS.
            TELEDENTAL - DENTISTRY VIRTUAL TELEMEDICINE TECHNOLOGY IS NO
            SUBSTITUTE FOR FULL IN OFFICE DENTAL EXAM, DENTAL X-RAYS AND FULL
            MEDICAL HISTORY RESEARCH. IT CAN BE USEFUL FOR GENERAL INFORMATION,
            TO GET SOME NEEDED SUGGESTIONS, MAYBE TO GET BASIC MEDICATIONS OR TO
            SCHEDULE APPOINTMENTS.
          </strong>
        </p>
        <p>
          <strong>
            <u>PRESCRIPTION SERVICES:</u>
          </strong>
        </p>
        <p>
          <strong>
            FOR SOME PEOPLE, THEY ONLY NEED ANTIBIOTICS - SINCE HAVE NO ACCESS
            TO THEIR DENTIST OR PHYSICIAN. IN OUR GOAL OF HELPING, WE DO THIS
            SERVICE. ANY USER USING THIS SERVICE, SHOULD UNDERSTAND - THAT IT IS
            BETTER TO GET THIS FROM YOUR LOCAL DENTIST OR LOCAL PHYSICIAN.
            PLEASE MAKE SURE NOT TO TAKE ANY ANTIBIOTICS MAY BE ALLERGIC TO. IF
            GET ANY KIND OF RASH OR ITCHING, SHOULD CONSULT YOUR LOCAL DENTIST
            OR PHYSICIAN. WE MAY PROVIDE PRESCRIPTION FOR ANTIBIOTICS OR
            MEDICATIONS. WE ASSUME ALL INFORMATION PROVIDED TO US, IS ACCURATE.
            WE DO NOT SELL ANY MEDICATIONS DIRECTLY. MEDICATIONS ARE NOT A
            SUBSTITUTE FOR DENTAL TREATMENT OR DENTAL CARE. IF PRESCRIBED ANY
            MEDICATIONS, AND FEEL ITCHING OR STARTING TO BREAK OUT, YOU MAY BE
            HAVING AN ALLERGIC REACTION? YOU SHOULD STOP TAKING AND SEE A DOCTOR
            / DENTIST / HOSPITAL AS SOON AS POSSIBLE. ANY PRESCRIPTIONS PROVIDED
            TO PATIENTS, WE MUST HAVE INFORMATION ABOUT WHETHER AN INDIVIDUAL
            HAS ANY TYPE OF ALLERGIES, ETC. IF GIVEN ANY TYPE OF ANTIBIOTICS,
            YOU SHOULD KNOW AND UNDERSTAND - IF FOR ANY REASON HAVE SOME KIND OF
            ITCHING, ALLERGIES, ETC. - YOU NEED TO SEE A DOCTOR, DENTIST OR
            HOSPITAL AS SOON AS POSSIBLE. WE EXPECT ALL PEOPLE PROVIDED
            ANTIBIOTICS TO SEE THEIR LOCAL DENTIST FOR TREATMENT AS SOON AS
            POSSIBLE. IF HAVE A TOOTH ABSCESS, WILL NEED DENTAL TREATMENT.
            ANTIBIOTICS ARE NOT MEANT TO BE A SUBSTITUTE FOR DENTAL TREATMENT.
            IF HAVE A TOOTH ABSCESS, MOST LIKELY, FOR INSTANCE, MAY NEED ROOT
            CANAL OR MAY END UP HAVING TO TAKE THE TOOTH OUT. CONSULT WITH YOUR
            LOCAL DENTIST OR ORAL SURGEON AS SOON AS POSSIBLE.
          </strong>
        </p>
        <p>
          <strong>
            TeleMedicine and TeleDental Video Consulting - all those using our
            teledental services and video dental services agree to the
            following. Telemedicine services and teledental services - that is
            TeleMed, TeleMedicine, TeleDentistry or TeleDental wording are being
            used interchangeably. All are meaning that virtual online
            communication between the business / doctor / dentist / staff and
            with the patient/ website user/ app user/ teledental user.
            Teledental provides a form of Telemedicine service either through
            teledental.com or teledental app(s). Each dentist is responsible for
            whatever advice they provide. Each staff members or business
            owner-employees and the users are responsible on how they use this
            technology. Just like doing a personal call with a prospective
            patient, each person doing the conversation or consulting should use
            best practice standards. Teledental, inc. is in no way responsible
            for how our Teledental technology is being used between 2 people.
            Both the patient (user) and dentist/ dental business professionals/
            staff are responsible for how they use this technology. Like all
            technologies, personal responsibility comes into play. A TELEDENTAL
            TELEDENTAL ONLINE VIRTUAL CONSULT HAS LIMITATIONS. ALL INDIVIDUALS
            RECEIVING ANY FORM OF CONSULTATION WILL NEED TO DO THEIR OWN DUE
            DILIGENCE. USUALLY, ONLINE VIRTUAL CONSULTATIONS CAN NOT BE A
            SUBSTITUTE FOR AN IN-PERSON DENTAL EXAM & DENTAL X-RAYS. THEY CAN BE
            USEFUL TO GET TO KNOW OR TO GET A GENERAL IDEA OF WHAT IS GOING ON.
            ALL INDIVIDUALS USING ANY FORM OF THE TELEDENTAL INC. TECHNOLOGY
            UNDERSTAND THAT THEY ARE PERSONALLY RESPONSIBLE ON HOW THEY USE IT.
            FURTHERMORE, BY USING TELEMEDICINE OR TELEDENTAL TELEDENTAL SERVICE,
          </strong>
        </p>
        <p>
          <strong>
            YOU AS THE USER OR THE ONE PROVIDING SERVICE ACCEPT THE FOLLOWING
          </strong>
        </p>
        <p>
          <strong>
            I understand that my health care provider wishes me to engage in a
            telemedicine consultation. 2. My health care provider has explained
            to me how the video conferencing technology will be used to affect
            such a consultation will not be the same as a direct patient/health
            care provider visit due to the fact that I will not be in the same
            room as my health care provider. 3. I understand there are potential
            risks to this technology, including interruptions, unauthorized
            access, and technical difficulties. I understand that my health care
            provider or I can discontinue the telemedicine consult/visit if it
            is felt that the videoconferencing connections are not adequate for
            the situation. 4. I understand that my healthcare information may be
            shared with other individuals for scheduling and billing purposes.
            Others may also be present during the consultation other than my
            health care provider and consulting health care provider in order to
            operate the video equipment. The above-mentioned people will all
            maintain the confidentiality of the information obtained. I further
            understand that I will be informed of their presence in the
            consultation and thus will have the right to request the following:
            (1) omit specific details of my medical history/physical examination
            that are personally sensitive to me; (2) ask non-medical personnel
            to leave the telemedicine examination room: and or (3) terminate the
            consultation at any time. 5. I have had the alternatives to a
            telemedicine consultation explained to me, and in choosing to
            participate in a telemedicine consultation. I understand that some
            parts of the exam involving physical tests may be conducted by
            individuals at my location at the direction of the consulting health
            care provider. 6. In an emergent consultation, I understand that the
            responsibility of the telemedicine consulting specialist is to
            advise my local practitioner and that the specialist’s
            responsibility will conclude upon the termination of the video
            conference connection. 7. I understand that billing will occur from
            both my practitioner and as a facility fee from the site from which
            I am presented. 8. I have had a direct conversation with my doctor,
            during which I had the opportunity to ask questions in regard to
            this procedure. My questions have been answered and the risks,
            benefits and any practical alternatives have been discussed with me
            in a language in which they understand.
          </strong>
        </p>

        <p>
          With Teledental.com.com - You shall be subject to any posted
          guidelines or rules applicable to such service which may be posted
          from time to time. All such guidelines or rules are hereby
          incorporated by reference into the Terms of Use. By accessing and
          using the Services, you are agreeing to be legally bound by these
          Terms of Use. Many of these rules and terms are standard online and
          internet guidelines that many other online sites use. By using our
          site, you accept to hold harmless “Teledental.com” for any
          errors/mistakes with data, coupons, news, ads or content that may be
          present on any of our sites. The discussion forum, news, data postings
          – Do Not represent Teledental.com views. We do want our site(s) to be
          as good as possible and take into consideration our members' and
          viewers' concerns. You may contact us at
          <a href="mailto:info@teledental.com"> info@Teledental.com</a> or{" "}
          <a href="mailto:contact@teledental.com">contact@Teledental.com</a> or{" "}
          <a href="mailto:signup@teledental.com">signup@Teledental.com</a> for
          support, or if have questions. Replies may take some time. We do not
          guarantee a reply to all questions or feedback, as can get thousands
          of spam mail etc. We ask all the information on any of our sites to be
          double-checked for accuracy. We try our best to provide great service
          and accurate data. Though, with online search, data, coupons, news –
          errors are bound to happen. We use fairly standard online internet
          guidelines for terms of use and privacy policy in social media and
          online search have incorporated and modified terms of use and privacy
          policy to correspond accordingly. This is a modification of stated
          internet guidelines for Teledental.com. Teledental.com.com is
          currently a Delaware company. Any legal issues would be governed by
          the state of Delaware and would be administered in Delaware.
        </p>
        <p>Dental pictures provided to us:</p>
        <p>
          Teledental has helped many, many people with various dental questions
          or needs. Our goal is to strive to be the best. We do allow a certain
          amount of anonymity, for those who rightfully do not want to share
          their exact identity with us. We will answer your dental question,
          even if only using an alias or nickname. We do expect people to use
          their own good judgment. We do try at Teledental to keep private as
          much as can your personal information and try to provide useful dental
          information. Anytime using an app or website, you as an individual
          should know there is never 100% security or lapses. Hence, we do not
          want your very personal information - that do not want anyone to ever
          know. Understand, that by using an app or website, there are certain
          risks as far as personal information. Any dental pictures may or may
          not be used for promotional purposes, though we will not put an
          individual's full name next to any dental pictures. We also try to not
          use an individual's full-face image, mostly pictures of teeth and part
          of the face. Our goal is to keep an individual's information as
          private as possible, though we do at times need to use some dental
          pictures.
        </p>
        <p>
          Our goal is for you the user to enjoy our site while understanding not
          to harass others, send spam, misuse information, understand all
          information on our sites has to be checked for accuracy – as the time
          to time, errors may be made, verify all coupons, offers or
          suggestions. Prior to use – as independent owner businesses may have
          varying rules and may change acceptance based on their business – know
          that we may delete or discontinue your use of service on any or all of
          our affiliate sites at our discretion anytime, without notice. OUR
          GOAL IS TO PROVIDE PREMIUM SERVICE TO THE CONSUMER AND PROFESSIONAL.
          BY USING THIS SITE, YOU THE USER AND PROFESSIONAL BUSINESS ARE
          HOPEFULLY GOING TO ENJOY AND GAIN INFORMATION. YOU UNDERSTAND TO USE
          LAWFULLY AND RESPECTFULLY. CHECK ALL FACTS AND DATA (ERRORS MAYBE
          MADE). PLEASE VERIFY ALL COUPONS AT TELEDENTAL.COM AND ANY OF IT’S
          AFFILIATE SITES. WE CANNOT GUARANTEE ANY BUSINESS COUPONS, AS TERMS
          MAY CHANGE AT TIMES WITHOUT OUR KNOWLEDGE. HENCE, MUST VERIFY ANY
          COUPON, PRIOR TO USE. DOUBLE CHECK ALL FACTS AND DATA INDEPENDENTLY.
          WE DO NOT SUPPORT ANY FORUM POSTING OR COMMENT. THESE ARE THE
          INDEPENDENT OPINIONS OF THE WRITER OR EDITOR. TERMS ARE SUBJECT TO
          CHANGE WITHOUT NOTICE. ALL FACTS AND DATA SHOULD BE INDEPENDENTLY
          CHECKED. WE STRIVE TO PROVIDE THE BEST SITE, AND CAN BE NOTIFIED FOR
          ANY IRREGULARITIES. ANY ACTION MAY TAKE TIME AND IS NOT GUARANTEED. WE
          STRIVE TO PROVIDE THE GOLD STANDARD IN SERVICE FOR CONSUMERS AND
          BUSINESSES.
        </p>
        <p>
          WE DO NOT ENDORSE ANY DENTIST / DENTAL PRACTICE. ALL SUGGESTIONS OR
          CONSULTS ARE THEIR OWN VIEW. ANY USER OF TELEDENTAL NEEDS TO DO THEIR
          OWN RESEARCH AND GET SECOND OPINION AND 3RD DENTIST OPINIONS BY
          VARIOUS SOURCES.
        </p>
        <p>
          ANY IMAGES PLACED ON OUR SITE CAN BE USED BY TELEDENTAL. WE ARE
          CURRENTLY NOT ASKING FOR FULL NAME SO NO REAL IDENTITY CAN BE PLACED
          WITH IT.
        </p>
        <p>
          BY USING OR OTHERWISE ACCESSING THE SERVICES AND/OR BY CREATING AN
          ACCOUNT WITH US, YOU AGREE TO THE TERMS OF THIS AGREEMENT, INCLUDING
          THE INFORMATION PRACTICES DISCLOSED IN OUR PRIVACY POLICY, THE USER
          RESTRICTIONS IN THE ACCEPTABLE USE POLICY AND THE TERMS AND CONDITIONS
          IN THE ADDITIONAL TERMS. IF YOU DO NOT AGREE WITH THIS AGREEMENT, YOU
          CANNOT USE THE SERVICES PROVIDED BY TELEDENTAL & ANY OF ITS SITES /
          PROPERTIES.
        </p>
        <p>
          Please note that we offer the Services “AS IS” and without warranties.
          If you create an account or use the Services on behalf of an
          individual or entity other than yourself, you represent that you are
          authorized by such individual or entity to accept this Agreement on
          such individual’s or entity’s behalf.
        </p>
        <p>
          ANY FEEDBACK PROVIDED VIA OUR CHAT OR DENTIST RESPONSE, IS ONLY THE
          START OF WHAT NEEDS TO BE DONE. WITHOUT A FULL IN DENTAL OFFICE EXAM &
          XRAYS, TELEDENTAL IS ONLY PROVIDING SUGGESTIONS - IN CASE OF DENTAL
          EMERGENCY WE SUGGEST GOING TO YOUR DENTIST OR THE ER HOSPITAL AS SOON
          AS CAN. WE EXPECT ALL OUR USERS TO USE COMMON SENSE AND UNDERSTAND
          THAT TELEDENTAL IS A SUPPLEMENTAL SERVICE WITH A GOAL OF PROVIDING
          MORE INFORMATION THAT MAY OR MAY NOT BE USEFUL. WITHOUT A FULL DENTAL
          EXAM AND XRAYS, THIS IS ONLY MEANT AS A SUGGESTION.
        </p>
        <p>
          OUR ULTIMATE GOAL IS TO MAKE THIS AN ENJOYABLE EXPERIENCE WITH US AND
          MAKE OUR GROUP OF SITES A LEADER IN ONLINE SEARCH, SOCIAL MEDIA AND
          MORE.
        </p>
        <p>
          WE WELCOME ALL COMMENTS OR QUESTIONS. MOST OF THESE TERMS AND
          CONDITIONS ARE COMMON AMONG SOME AFFILIATE MARKETING COMPANIES – WHICH
          WE HAVE USED ON HERE.
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Teledental.com provides business information to contact users for
            appointments requested, services – interested in stated services of
            stated professions and users. This can be in the form of a service
            or an appointment etc. Unless explicitly stated otherwise, any new
            features that augment or enhance the current services shall be
            subject to these Terms of Use. You understand and agree that the
            services provided through the Services are provided “AS IS” and that
            Teledental.com, Teledental.com or any of its affiliates assumes no
            responsibility for the timeliness, deletion, missed delivery, or
            failure to store any user communications or personalization
            settings. Furthermore, no coupons are guaranteed any value – that
            is, all coupons must be first verified by the owner of the business
            PRIOR to use. Some coupons may not be in current use or have been
            ended by the business. Hence, all coupons must be checked for
            accuracy and be validated prior to use.
          </li>
          REVIEWS AND TESTIMONIALS:
          <p>
            All reviews and testimonials placed on Teledental App or
            Teledental.com, or affiliate sites - can and may be shared with all.
            Writing a review or testimonial is to provide potential users or
            users of our services, for the benefit of Teledental.
          </p>
          <p>EDUCATIONAL PURPOSES:</p>
          <p>
            Our goal is to have the utmost privacy on our site. Furthermore, we
            do allow our users to use just a last name initial - instead of a
            full last name ... so as to allow some personal privacy and
            anonymity. Like all sites, there are many users and people
            administering our website and app. Those people who wish to have
            100% privacy in a closed setting such as a doctor's office, should
            NOT use our online services. Anytime searching online, using an app
            or other online services - there usually is some form of personal
            information that others may unintentionally see. All people using
            our site, we do not force to use or know actual identity of the
            person - that is using our site. Many people using our site use a
            nickname or alias when asking a general question? People use various
            profile names, abbreviations, or possible nicknames. Hence, with our
            chat admin - the names on there may or may not be accurate? We do at
            times do live demo of our site for dentists, professionals, and
            those wanting to work with us. Hence, all those that absolutely want
            to be anonymous - can use a nickname. Furthermore, we do not ask for
            a complete address of individuals. We ask for the city and state -
            again, this may or may not be accurate? We will answer people's
            questions from all over and do not need accurate personal
            information as far as the person's identity of the location of the
            person. The exception to this is if someone - is if a person is
            seeking a dentist in their area. Also, if a person is seeking
            antibiotics and medications - then the person needs to provide
            accurate information to us. Then the general chatbot area is where
            we may use demo of our site. If do not want to share your identity,
            you can provide an alias name there. We do try our best to keep your
            personal information as private as possible. Our policy is to not
            share an individual's personal information with other companies,
            which is shared with people's emails. We do use images shared to us
            of teeth or portions of the face, for promotional purposes. We do
            NOT put an individual's name next to it. We try not to put a full
            face on any promotions.
          </p>
          <p>
            You may not use the Services for any unlawful purpose.
            Teledental.com Inc may change any term in these Terms of Use at any
            time. The changes will appear in these Terms of Use, and your use of
            the Services after any changes have been posted will constitute your
            agreement to the modified Terms of Use and all of the changes.
            Therefore, you should read these Terms of Use each time you access
            the Services before you begin using the Services. You may print a
            copy of these Terms of Use for your reference.
          </p>
          <p>
            You understand and agree that Teledental.com and its site may
            discontinue or change the Services at any time, without notice. You
            also understand and agree that Teledental.com may discontinue or
            restrict your use of the Services for any reason without notice.
          </p>
          <li>
            The content displayed by Teledental.com on the Services other than
            “User Content” as defined below (“Teledental.com Content” and
            together with “User Content”, “Content”) is the property of
            Teledental.com or its licensors and is protected by intellectual
            property laws. You agree not to copy, reproduce, modify, display,
            perform, publish, translate, create derivative works based upon or
            store any Content from the Services. You also agree not to
            distribute, transmit, broadcast or circulate any Content from the
            Services to anyone, including but not limited to someone else in the
            same company or organization, without the express prior written
            consent of Teledental.com.
          </li>
          <li>
            You will receive a password and account designation upon completing
            the Teledental.com registration process and becoming a member. These
            passwords are the property of Teledental.com and for security
            reasons must not be disclosed to any other party. You are
            responsible for maintaining the confidentiality of the password and
            account and are fully responsible for all activities that occur
            under your password or account. You agree to (a) immediately notify
            Teledental.com of any unauthorized use of your password or account
            or any other breach of security, and (b) ensure that you exit from
            your account at the end of each session. Teledental.com cannot and
            will not be liable for any loss or damage arising from your failure
            to comply with this Section 3.
          </li>
          <li>
            You understand that all information, data, text, and other
            materials, whether publicly posted or privately transmitted, are the
            sole responsibility of the person from whom such information, data,
            text, or materials originated. This means that you, and not
            Teledental.com, are entirely responsible for all information, data,
            text, and other materials that you upload, post, e-mail, transmit,
            or otherwise make available via the Services (“User Content”).
          </li>
        </ul>
        <p>
          <strong>
            PLEASE USE COMMON SENSE WHEN USING OUR SITE. WE PROVIDE DENTAL
            INFORMATION THAT MAY OR MAY NOT BE ACCURATE. WE ALWAYS RECOMMEND
            DOING A FULL DENTAL EXAM & DENTAL XRAYS IN THE DENTAL OFFICE, TO
            HAVE THE BEST CHANCE OF FINDING OUT WHAT IS HAPPENING. OUR SITE IS
            MEANT AS AN ADJUNCT - WE TRY TO HELP OUR USERS WITH MORE INSIGHT
            THAT MAY OR MAY NOT BE USEFUL.
          </strong>
        </p>
        <p>
          COUPONS: Like any online service, there may be errors in content,
          hacking or other mistakes. We try our best to limit this. Hence, ALL
          COUPONS MUST BE VERIFIED WITH THE BUSINESS OWNER PRIOR TO USE – SO, AS
          TO DIMINISH OR ELIMINATE ANY MISUNDERSTANDING. TELEDENTAL.COM DOES NOT
          ACCEPT ANY DAMAGES OR ERRORS BETWEEN BUSINESSES OR CUSTOMERS. WE DO
          NOT PROVIDE ANY DIRECT SERVICE TO THE CONSUMER IN WAY OF COUPONS AND
          DO NOT PROVIDE ANY REAL DOLLARS OF VALUE. All coupons posted on any of
          our sites HAVE to be first verified at the business PRIOR to use ----
          as some businesses change their promotions at will and may not notify
          us. You agree that you will not use any robot, spider, other automatic
          devices, or manual process to monitor or copy the Services or the
          content contained therein without our prior express written consent.
          You agree that you will not use any device, software, or routine to
          interfere or attempt to interfere with the proper working of the
          Services or any activities conducted on the Services. You agree that
          you will not copy, reproduce, alter, modify, or create derivative
          works from the Services without the prior express permission.
        </p>
        <p>
          For Affiliate Marketers, Affiliate Marketing Companies & Local
          Business Listings On Teledental.com.com -?Genuine Actions -You shall
          not create Licensed Applications with capabilities that in any way do
          not reflect explicit, genuine expressions of user intent and
          permission. For example, Your Applications should not automatically
          create contracts, should not establish joint venture relationships, or
          set affiliate commission values that the user has not explicitly
          authorized without (1) a specific interaction informing a user that
          such user is entering an agreement and (2) an explicit action by such
          user evincing permission for making such agreement.
        </p>
        <ul>
          <li>
            <strong>
              Indicating Use of the Teledental.com Services and the
              Teledental.com API.
            </strong>{" "}
            In a prominent location in all Licensed Applications, You should, to
            the extent, reasonably based on the nature of the Licensed
            Application, indicate that such Licensed Applications have been
            created using the Teledental.com Services and/or the Teledental.com
            API, and shall comply with the Teledental.com Trademark Guidelines
            in doing so.
          </li>
          <li>
            <strong>Use of Teledental.com Marks.</strong> You may not, under any
            circumstances: (i) include in or use the Teledental.com Marks, or
            any marks that are confusingly similar to or derivative of the
            Teledental.com Marks ("Confusing Marks"), as part of Your trade name
            (registered or otherwise), logos or other identifiers; (ii) include
            in or use the Teledental.com Marks or any Confusing Marks as part of
            any names, domain names logos or other identifiers of Your
            Applications; or (iii) use the Teledental.com Marks or Confusing
            Marks in a manner that creates or may create a sense of endorsement,
            sponsorship or association with Teledental.com, unless expressly
            permitted by Teledental.com, in writing, to do so. All use of the
            Teledental.com Marks, and any goodwill arising out of such use,
            shall inure to the benefit of Teledental.com. You may freely state
            and disclose that You are using the Teledental.com Services or the
            Teledental.com API, as long as You adhere to all restrictions on
            using the Teledental.com Marks set forth in this Agreement, the
            Teledental.com Trademark Guidelines, and the Client Contract.
          </li>
          <li>
            <strong>No Misleading Users.</strong> You may not, under any
            circumstances, whether within a Licensed Application or in materials
            discussing or concerning a Licensed Application, mislead, confuse or
            cause misapprehension among users as to the features, functionality,
            origin, capabilities or other aspects of said Licensed Application,
            the Teledental.com Services or the Teledental.com API. You should
            not advertise or otherwise discuss your application in a way that
            confuses or misleads users about the application or about
            Teledental.com.
          </li>
          <li>
            Monitoring. Teledental.com reserves the right to monitor Your use of
            the Licensed Materials for any reason or no reason, including to
            ensure Your compliance with the terms and conditions of this
            Agreement.
          </li>
          <li>
            Reporting and Statistics. You shall ensure that Your applications
            properly use all reporting functionality that is made available
            through the Teledental.com API that is used in or by Your
            applications.
          </li>
          <li>MODIFICATIONS:</li>
        </ul>
        <p>
          You acknowledge and agree that Teledental.com may modify this
          Agreement, the Teledental.com Services, the Teledental.com API and
          Teledental.com Content Policy from time to time (a "Modification").
          Except for modifications of the Teledental.com Services, You will be
          notified of a Modification through notifications or posts on the
          Developer Website or through a form of direct communication from
          Teledental.com to You including but not limited to e-mail or
          notifications through the Teledental.com Services ("Client Notice").
          You further acknowledge and agree that the Teledental.com Services and
          the Licensed Materials may be modified at any time and without any
          notice to You. You shall, within thirty (30) days from the date of
          first notice of any Modification(s) (or such shorter period of time
          specified in the notice of the Modification(s)) comply with such
          modification(s) by implementing and using the most current version of
          the Teledental.com API and making any changes to Your Applications
          that may be required as a result of such Modification(s). You
          acknowledge that a Modification may have an adverse effect on Your
          Applications, including but not limited to changing the manner in
          which Your Applications communicate with Teledental.com Services and
          display Content. You acknowledge that Your sole recourse for such
          adverse effects is to terminate this Agreement pursuant to Section 10
          and that Your continued access to or use of the Teledental.com
          Services or any Licensed Materials following such thirty (30) day
          period shall constitute binding acceptance of the Modification(s) at
          issue. Teledental.com will attempt but is not obligated, to provide
          thirty (30) days notice of any Modification that is not backward
          compatible or that Teledental.com reasonably believes will remove or
          materially alter significant functionality of the Teledental.com API.
        </p>
        <p>
          WE VALUE OUR USERS PRIVACY. WE DO NOT ASK FOR YOUR DATE OF BIRTH OR
          SOCIAL SECURITY NUMBER, ETC. - IF SEE ANYTHING LIKE THAT ON OUR SITE,
          PLEASE NOTIFY US. WE ASK OUR USERS TO NOT SHARE INFORMATION THAT THEY
          DEEM IS VERY CONFIDENTIAL OR THEY ARE VERY PROTECTIVE OF. ANYTIME
          USING AN ONLINE SERVICE, AS MUCH AS WE AND ALL COMPANIES TRY OUR BEST,
          THE POSSIBILITY EXISTS FOR DATA BEING SHARED ON THE WEB. WE HAVE YET
          TO HAVE THIS HAPPEN, BUT THAT IS A POSSIBILITY.
        </p>
        <p>
          <strong>Ownership:</strong>
        </p>
        <ul>
          <li>
            <strong>Teledental.com.</strong> You acknowledge and agree that
            Teledental.com and its licensors, retain all worldwide right, title
            and interest in and to the Licensed Materials, including all
            worldwide intellectual property rights therein. We at Teledental
            reserve the right to make changes to content. That is,
            Teledental.com reserves the right to edit, delete or modify content
            placed on this site. ANY INFORMATION PLACED ON THIS TELEDENTAL.COM
            BY ANY VISITOR IS OPTIONAL. For those that do not want to share
            their information, please do not use Teledental.com. We are looking
            to share with one another information and to help people with their
            dental care needs. WE IN NO WAY MAKE ANY PROMISE OF DENTAL
            SERVICE(S) OR MAKE ANY CLAIMS TO ACCURACY OF ANY INFORMATION
            PROVIDED ON TELEDENTAL.COM. WE WILL TRY TO MAKE THIS THE BEST SITE
            WE CAN, AND WILL TRY TO CONTINUALLY IMPROVE OUR SERVICES. You also
            acknowledge and agree that, as between You and Teledental.com,
            Teledental.com owns all right, title and interest in and to the
            Teledental.com Services, the Teledental.com API, the Teledental.com
            Marks, and any derivative works or enhancements thereof, including
            but not limited to all worldwide intellectual property rights
            therein. You agree not to act in any manner inconsistent with such
            ownership rights. Any of Teledental.com's rights not expressly
            granted under this Agreement are retained. You agree that You will
            not challenge Teledental.com's ownership of the Teledental.com
            Marks, challenge the validity of the licenses granted under this
            Agreement, or otherwise copy or exploit the Teledental.com Marks
            during or after the termination of this Agreement, except as
            expressly authorized under this Agreement. If You acquire any rights
            in the Teledental.com Marks or any confusingly similar marks, by
            operation of law or otherwise, You will, at no expense to
            Teledental.com, immediately effectuate an assignment of such rights
            to Teledental.com.
          </li>
          <li>
            <strong>You.</strong> As between You and Teledental.com, You retain
            all worldwide right, title, and interest in and to Your
            Applications, excluding the Teledental.com Services, the Licensed
            Materials, the Teledental.com Marks, and any derivative works or
            enhancements thereof, including but not limited to all intellectual
            property rights therein.
          </li>
          <li>
            <strong>
              Feedback. You may provide Teledental.com with feedback or comments
              related to the Licensed Materials and/or Your experience with and
              use thereof ("Feedback"). You agree that Teledental.com and its
              designees and assigns shall be free to copy, modify, create
              derivative works of, publicly display, disclose, distribute,
              license, sublicense, incorporate and otherwise use Feedback,
              including all derivative works thereof, for any and all purposes,
              commercial or otherwise, with no obligation of any kind to You.
            </strong>
          </li>
        </ul>
        <p>
          ABOUT TELEDENTAL / IMPORTANT INFORMATION: We are using many of the
          same standards that many of the telemedicine / online healthcare
          platforms use. We are looking to always improve. All TERMS AND
          CONDITIONS STATED HERE MAY BE CHANGED WITHOUT NOTICE.
        </p>
        <p>
          Part of the Services can be viewed without an account with us. To
          benefit from all of the Services we offer, you can create a Teledental
          account and provide certain basic information about yourself, which
          you authorize Teledental to use - as has been disclosing and described
          in our Privacy Policy.
        </p>
        <p>
          You acknowledge that although some Content may be provided by
          healthcare professionals, the provision of such Content does not
          create a medical professional/patient relationship, and does not
          constitute an opinion, medical advice, or diagnosis or treatment, but
          is provided to assist you in choosing a doctor, dentist or another
          healthcare specialist, professional, provider, or organization
          (collectively, “Healthcare Provider”). “Content” means content, text,
          data, graphics, images, photographs, video, audio, information,
          suggestions, guidance, and other materials provided, made available or
          otherwise found through the Services and/or Site, including, without
          limitation, Content provided in direct response to your questions or
          postings.
        </p>
        <p>
          WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE YOU WITH ACCURATE CONTENT,
          WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES, WHETHER
          EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL QUALIFICATIONS,
          EXPERTISE, QUALITY OF WORK, PRICE OR COST INFORMATION, INSURANCE
          COVERAGE OR BENEFIT INFORMATION, OR ANY OTHER CONTENT AVAILABLE
          THROUGH THE SERVICES. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANYONE
          ELSE FOR ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON ANY
          SUCH CONTENT. FURTHERMORE, WE DO NOT IN ANY WAY ENDORSE OR RECOMMEND
          ANY INDIVIDUAL OR ENTITY LISTED OR ACCESSIBLE THROUGH THE SERVICES.
        </p>
        <p>
          <strong>
            WE DO NOT PROVIDE DENTAL ADVICE OR MEDICAL ADVICE - WE ONLY PROVIDE
            SUGGESTIONS THAT NEEDS TO BE CAREFULLY SCREENED. WE BELIEVE THE MORE
            INFORMATION A PERSON HAS, THE BETTER THE DECISION ONE CAN TAKE ON
            THEIR OWN BEHALF. DENTAL CARE CAN AT TIMES BE SUBJECTIVE AND NOT
            OBJECTIVE. YOU MAY HAVE 10 DENTISTS WITH SEVERAL DIFFERING VIEWS OF
            BEST DENTAL TREATMENT FOR THE SAME PATIENT. HENCE, ANY USER USING
            OUR SITE ACCEPTS THAT WE DO NOT ENDORSE ANY DENTIST OR ENDORSE ANY
            VIEWS OR ANY SUGGESTIONS. WE CAN NOT BE CERTAIN ANY SUGGESTION IS
            ACCURATE OR NOT - AS MUCH AS WE MAY TRY TO PROVIDE GOOD DENTAL
            INFORMATION. ALL USERS NEED TO DO THEIR OWN DUE DILIGENCE. GETTING
            DENTAL EXAM / PROPHY IS RECOMMENDED BY MOST DENTAL ORGANIZATIONS AT
            YOUR LOCAL DENTAL OFFICE EVERY 6 MONTHS OR POSSIBLY MORE. TO DO
            THOROUGH DENTAL EXAM, IT IS BEST TO HAVE DENTAL EXAM DONE BY A LOCAL
            DENTIST IN A LOCAL DENTAL OFFICE.
          </strong>
        </p>
        <p>
          <strong>
            ANY PERSON WITH ANY KIND OF DENTAL EMERGENCY SHOULD SEEK TO BE SEEN
            AT A LOCAL DENTAL OFFICE FOR DENTAL TREATMENT - IT IS THE USERS
            RESPONSIBILITY TO SEE TO THAT. THE USER WILL NEED TO USE THEIR OWN
            JUDGEMENT,
          </strong>
        </p>
        <p>
          The Content that you obtain or receive from Teledental, its employees,
          contractors, partners, sponsors, advertisers, licensors, or otherwise
          through the Services, is for informational purposed, scheduling and
          payment purposes only. All DENTAL & MEDICAL related information,
          including, without limitation, information shared via Teledental,
          blog, social media channels, emails and text messages, and advertising
          comes from independent healthcare professionals, dentists and
          organizations which is for informational purposes only - and
          represents their own views. In the dental space, a patient can get 3
          different treatment plans from 3 different dentists. We expect all
          users of our site to use their own best judgment and use the
          information they receive as more information that may or may not be
          useful to them. Furthermore, all sites can be hacked or compromised at
          times. We @ Teledental will try our best to keep everything as secure
          as we can.
        </p>
        <p>
          WHILE WE HOPE THE CONTENT IS USEFUL IN YOUR HEALTHCARE JOURNEY, IT IS
          NOT INTENDED AS A SUBSTITUTE FOR, NOR DOES IT REPLACE, PROFESSIONAL
          DENTAL / MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. DO NOT DISREGARD,
          AVOID OR DELAY OBTAINING MEDICAL ADVICE FROM A QUALIFIED HEALTHCARE
          PROVIDER, UNDER ANY CIRCUMSTANCE. DO NOT USE THE SERVICES FOR
          EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A MEDICAL EMERGENCY,
          IMMEDIATELY CALL A HEALTHCARE PROFESSIONAL AND 911. YOUR USE OF THE
          CONTENT IS SOLELY AT YOUR OWN RISK. NOTHING STATED OR POSTED ON THE
          SITE OR AVAILABLE THROUGH ANY SERVICES IS INTENDED TO BE, AND MUST NOT
          BE TAKEN TO BE, THE PRACTICE OF MEDICINE, DENTISTRY, NURSING, OR OTHER
          PROFESSIONAL HEALTHCARE ADVICE, OR THE PROVISION OF MEDICAL CARE.
        </p>
        <p>
          We do not recommend or endorse any specific tests, Healthcare
          Providers, procedures, opinions, or other information that may appear
          through the Services. If you rely on any Content, you do so solely at
          your own risk. We encourage you to independently confirm any Content
          relevant to you with other sources, including the Healthcare
          Provider’s office, medical associations relevant to the applicable
          specialty, your state medical boards, and the appropriate licensing or
          certification authorities to verify listed credentials and education.
        </p>
        <p>NO DOCTOR-PATIENT RELATIONSHIP</p>
        <p>
          DOCTORS, DENTISTS, NURSES, AND OTHER MEDICAL PROFESSIONALS USE THE
          SERVICES TO SHARE CONTENT WITH YOU, BUT YOUR USE OF THIS CONTENT IS
          NOT A SUBSTITUTE FOR HEALTHCARE. NO LICENSED DENTISTS, MEDICAL
          PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED WHEN YOU USE THE SERVICES
          OR CONTENT. THIS IS TRUE WHETHER SUCH CONTENT IS PROVIDED BY OR
          THROUGH THE USE OF THE SERVICES OR THROUGH ANY OTHER COMMUNICATIONS
          FROM TELEDENTAL INCLUDING, WITHOUT LIMITATION ALL PAGES ON
          TELEDENTAL.COM / TELEDENTAL APP -- ALL EMAILS OR TEXT MESSAGE OR LINKS
          TO OTHER SITES, OR ANY ASSISTANCE WE MAY PROVIDE TO HELP YOU FIND AN
          APPROPRIATE HEALTHCARE PROVIDER IN ANY FIELD.
        </p>
        <p>
          We at Teledental encourage Healthcare Providers to use the Services
          responsibly, but we have no control over, and cannot guarantee the
          availability of, any Healthcare Provider at any particular time. We
          will not be liable for canceled or otherwise unfulfilled appointments,
          or any injury resulting therefrom, or for any other injury resulting
          or arising from, or related to, the use of the Site or Services
          whatsoever.
        </p>
        <p>
          - AUTHORIZATION AND ACKNOWLEDGEMENT; IMPORTANT INFORMATION ABOUT
          HEALTHCARE PROVIDER RELATIONSHIPS AND HEALTHCARE PROVIDER LISTS
        </p>
        <p>
          In connection with using the Site and the Services to locate and
          schedule appointments with Healthcare Providers, you understand that:
        </p>
        <p>YOU ARE RESPONSIBLE FOR CHOOSING YOUR OWN HEALTHCARE PROVIDER.</p>
        <p>
          Teledental makes reasonable efforts to ensure that Healthcare
          Providers only participate in the Services if they hold all active
          licenses required by law to practice the specialties of the services
          offered by them, and are not excluded from participation in the
          Medicare and Medicaid programs. Teledental may exclude Healthcare
          Providers who, in Teledental discretion, have engaged in inappropriate
          or unprofessional conduct.
        </p>
        <p>
          Some Healthcare Providers listed through the Services enter into
          contracts with us, and may pay us a fee in order to be marketed
          through the Services. To help you find Healthcare Providers who may be
          suitable for your needs, and enable the maximum choice and diversity
          of Healthcare Providers participating in the Services, we will provide
          you with lists and/or profiles of Healthcare Providers.
        </p>
        <p>
          These results are based on information that you provide to us, such as
          insurance information, geographical location, and healthcare
          specialty. They may also be based on other criteria (including, for
          example, Healthcare Provider availability, past selections by and/or
          ratings of Healthcare Providers by you or by other Teledental users,
          and past experience of Teledental users with Healthcare Providers).
          Note that Teledental (a) does not recommend or endorse any Healthcare
          Providers, (b) does not make any representations or warranties with
          respect to these Healthcare Providers or the quality of the healthcare
          services they may provide, and (c) does not receive any additional
          fees from Healthcare Providers for featuring them (i.e., higher or
          better placement on lists) through the Services (subject to Sponsored
          Results as described below). Note, however, to the extent that you use
          the Services as provided by your employer, Teledental may provide
          lists and/or profile previews based also on criteria determined by
          your employer and your employer’s agents or advisors.
        </p>
        <p>
          To provide you with even more options, we may show you sponsored
          results (“Sponsored Results”) on the Site, including above the search
          results. Teledental receives additional fees from Healthcare Providers
          for providing Sponsored Results. Sponsored Results shown through the
          Services are not, and should not be considered, an endorsement or
          recommendation by Teledental of the Healthcare Provider.
        </p>
        <p>
          THE SERVICES AND CONTENT ARE INFORMATIONAL AND EDUCAxTIONAL RESOURCES
        </p>
        <p>
          The Services are an informational and educational resource for
          consumers and Healthcare Providers. We may, but have no obligation to,
          publish Content through the Services that is reviewed by our editorial
          personnel. No party (including Teledental) involved in the preparation
          or publication of such works guarantee that the Content is timely,
          accurate or complete, and they will not be responsible or liable for
          any errors or omissions in, or for the results obtained from the use
          of, such Content.
        </p>
        <p>Healthcare Provider Content:</p>
        <p>
          Healthcare Provider and practice Content is intended for general
          reference purposes only. Healthcare Provider Content may be provided
          by the Healthcare Provider and/or office staff, and collected from
          multiple other data sources that may not be confirmed by the
          Healthcare Provider. Such Content can change frequently and may become
          out of date, incomplete or inaccurate. Neither the Site nor Teledental
          provides any advice or qualification certification about any
          particular Healthcare Provider.
        </p>
        <p>Procedures/Products/Services:</p>
        <p>
          The procedures, products, services, and devices discussed and/or
          marketed through the Services are not applicable to all individuals,
          patients or all clinical situations. Any procedures, products,
          services or devices represented through the Services by advertisers,
          sponsors, and other participants of the Services, either paid or
          unpaid, are presented for your awareness and do not necessarily imply,
          and we make no claims as to, safety or appropriateness for any
          particular individual or prediction of effectiveness, outcome or
          success.
        </p>
        <p>Insurance Content:</p>
        <p>
          The insurance and insurance-related Content (including, without
          limitation, insurance coverage and benefit Content) (“Insurance
          Content”) may be intended for general reference purposes and for your
          convenience only. Such Insurance Content may be based on Personal
          Information (as defined below) you provide to us as well as Healthcare
          Provider Content (such as CPT codes and other encounter data). The
          Insurance Content is provided either directly by the insurance
          provider you identify or via a third-party clearinghouse. Insurance
          Content can change frequently and may become out of date, incomplete
          or inaccurate. To make this Content as accurate as possible, you agree
          that you will (a) provide accurate and complete insurance-related
          Personal Information, and (b) verify and update your insurance-related
          Personal Information (including, without limitation, verifying such
          Personal Information obtained by automated means from an insurance
          card you provide). Although Teledental strives to provide you with
          accurate Insurance Content, we are not responsible for any inaccurate,
          incomplete or outdated Insurance Content, and you are responsible for
          complying with subparts (a) and (b) hereof.
        </p>

        <p>YOUR PERSONAL INFORMATION</p>

        <p>
          Protecting patient privacy and keeping your information secure are
          among our biggest priorities. This section will help you understand
          what information we ask for and why.
        </p>
        <p>
          To create a Teledental account, you must provide a few pieces of basic
          information about yourself. When you book an appointment through
          Teledental, we pass along this information to your Healthcare Provider
          on your behalf.
        </p>
        <p>
          If you wish to benefit from other Services we provide, you can choose
          to share additional information with Teledental. Our Privacy Policy
          details how we may use, share, and maintain the information that you
          voluntarily share with Teledental, which may include, without
          limitation, your name, address, social security number and contact
          information, insurance information, medical history and current
          medical needs, billing information, and other information
          (collectively, “Personal Information”).
        </p>
        <p>
          By submitting such information, you authorize Teledental, its
          employees, its agents and others operating on its behalf to use and/or
          disclose such information in accordance with our Privacy Policy.
          Please review the Privacy Policy carefully, as your use of the
          Services constitutes your agreement to it.
        </p>
        <p>
          You may elect to enter information into a medical history form
          (“Medical History Form”) on behalf of yourself or a third party from
          whom you have authorization to provide such information. You can
          request (and in doing so, authorize) Teledental to provide this
          information to your chosen Healthcare Provider. You acknowledge and
          agree that such information will be reviewed and approved by you or
          someone authorized by you at the time of your appointment to ensure
          its accuracy. You also acknowledge that Teledental may use the data or
          information you provide in a Medical History Form in accordance with
          our Privacy Policy.
        </p>
        <p>- YOUR RESPONSIBILITIES:</p>
        <p>- Your Account Credentials:</p>
        <p>
          When you create a Teledental account, you will provide an email
          address and create a password (collectively, “Credentials”). You
          should keep your Credentials private and not share your Credentials
          with anyone else. You must immediately notify us if your password has
          been stolen or compromised by sending an email to
          service@teledental.com.
        </p>
        <p>
          WE CAN NOT VERIFY INDIVIDUALS, AND TRUST THAT THEY ARE PROVIDING
          ACCURATE INFORMATION. WE GO BY THE INFORMATION PROVIDED TO US.{" "}
          <u>
            WE ONLY PROVIDE DENTAL SUGGESTIONS AND ADVISE ALL OUR USERS TO GET A
            FULL DENTAL EXAM AND PROPER DENTAL IMAGING X-RAYS AT A LOCAL DENTIST
            OFFICE{" "}
          </u>
          , TO GET A MORE ACCURATE IDEA OF WHAT IS GOING ON.
        </p>
        <p>Your Responsibilities Generally:</p>
        <p>
          <u>
            Teledental is NOT RESPONSIBLE for any dental expense or any medical
            expense. Teledental will Not Pay for any dental service provided to
            any individual.
          </u>{" "}
          That is, Teledental will not reimburse or pay any dental practice fee.{" "}
          <u>
            Any users referred to any dental practice are expected to do their
            own due diligence.{" "}
          </u>{" "}
          Usual, customary and any other charges for any dental, medical or
          related services rendered by Healthcare Providers will apply and will
          be entirely your responsibility. You must resolve any dispute between
          you or any Healthcare Provider arising from any transaction hereunder
          directly with the Healthcare Provider. We do NOT GUARANTEE ANY DENTAL
          SERVICES provided by any dentist we refer to or do not refer to. We
          expect all individuals to maintain their own dental care, their own
          good oral hygiene and to find the best local dentists that will
          provide the treatment they desire. Hence, all individuals using
          Teledental are responsible for their own dental decisions. Teledental
          is providing feedback that may or may not be accurate. Teledental does
          not do a full oral exam, does not take dental x-rays and does not know
          the full medical history of people that come onto our site. Hence, we
          can ONLY PROVIDE A DENTAL SUGGESTION that may or may not be right. Our
          goal is to provide good dental information, though dentists have
          different opinions on what is the best dental treatment. For example,
          holistic dentists may consider getting a porcelain dental crown as not
          being a good idea. While most dentists would differ in that opinion.
        </p>
        <p>
          You are responsible for all use of the Services and for all use of
          your Credentials, including use by others to whom you have given your
          Credentials. You may only use the Site and the Services for lawful,
          non-commercial purposes. You may not use the Site in any manner that
          could damage, disable, overburden, or impair our servers or networks,
          or interfere with any other party’s use and enjoyment of the Site or
          the Services. You may not attempt to gain unauthorized access to any
          of the Services, user accounts, or computer systems or networks,
          through hacking, password mining or any other means. You may not
          accumulate or index, directly or indirectly, any Content or portion of
          the Site and/or Services (including, without limitation, Healthcare
          Provider Content, appointment availability, price information, and
          Insurance Content) for any purpose whatsoever.
        </p>
        <p>
          You are also responsible for reviewing and complying with the terms
          set forth in our Acceptable Use Policy.
        </p>
        <p>
          In addition to our rights in these Terms of Use, we may take any legal
          action and implement any technological measures to prevent violations
          of the restrictions hereunder and to enforce these Terms of Use or our
          Acceptable Use Policy.
        </p>
        <p>
          Responsibilities of Healthcare Providers and Others in the Healthcare
          or Medical Industries
        </p>
        <p>
          If you are a Healthcare Provider or other person or entity in the
          healthcare or medical industries, regardless of whether you maintain
          an account with Teledental or whether you schedule or intend to
          schedule appointments (including appointments for Designated Provider
          Services, as defined in the Additional Terms) through the Services,
          you acknowledge and agree that:
        </p>

        <p>
          (a) You will not use the Services to view, access or otherwise use,
          directly or indirectly, price, availability, or other Content for any
          purpose other than your own personal use as a patient or prospective
          patient.
        </p>

        <p>
          (b) You will not use the Services to establish, attempt to establish,
          or enforce, directly or indirectly, any agreement or coordination of
          the prices charged for any product or service; the kinds, frequencies
          or amounts of any product or service offered; or the customer or
          customer categories for any product or service, or otherwise engage or
          attempt to engage in price-fixing, output restriction, or customer or
          market allocation.
        </p>
        <p>
          (c) You will not use the Services, directly or indirectly, to engage
          in any anti-competitive, deceptive or unfair practices, or otherwise
          violate applicable antitrust, competition or consumer protection laws,
          or regulations.
        </p>
        <p>
          TELEDENTAL HELPS PEOPLE FIND LOCAL DENTISTS OR DENTAL PRACTICES. ALL
          PEOPLE NEED TO DO THEIR OWN DUE DILIGENCE. TELEDENTAL DOES NOT OWN
          CURRENTLY ANY DENTAL PRACTICES. HENCE, ANY DENTAL SERVICES NEEDS TO BE
          WORKED OUT BY THE DENTAL PRACTICE AND THE PATIENT. TELEDENTAL TAKES NO
          RESPONSIBILITY FOR ANY DENTAL SERVICE.
        </p>
        <p>
          DENTAL PRACTICE SUBSCRIPTION: ANY DENTAL PRACTICE OR DENTIST THAT DOES
          SIGN UP WITH TELEDENTAL UNDERSTANDS THE FOLLOWING:
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            THE DENTAL PRACTICE MAY TERMINATE THE CONTRACT ANYTIME THEY CHOOSE.
            NO FURTHER CHARGES WILL BE DONE. NO REFUND OF MONEY PAID WILL BE
            DONE. ANY DENTAL PRACTICE THAT DOES TERMINATE SERVICE, WILL NEED
            SPECIAL WAIVER TO RETURN ONTO TELEDENTAL. LIKE ANY ONLINE SERVICE,
            TELEDENTAL BELIEVES DENTAL PRACTICES THAT WORK WITH TELEDENTAL FOR
            ONE YEAR OR MORE WILL SEE RESULTS AND BENEFITS.
          </li>
          <li>
            TELEDENTAL CAN NOT ABSOLUTELY GUARANTEE ANY RESULTS OR WHETHER
            PATIENTS WANT TO OR DO NOT WANT TO VISIT A DENTAL PRACTICE.
          </li>
          <li>
            TELEDENTAL IN NO WAY WILL PAY FOR ANY DENTAL TREATMENT OF ANY PEOPLE
            REFERRED. TELEDENTAL TAKES NO RESPONSIBILITY FOR ANY DENTAL
            TREATMENT BY ANY DENTAL PRACTICE.
          </li>
          <li>
            TELEDENTAL RESERVES THE RIGHT TO END CONTRACT OF DENTAL PRACTICES.
            THOSE PRACTICES WILL NOT HAVE TO PAY ANY FEES GOING FORWARD.
          </li>
          <li>ALL DENTAL PRACTICES ARE REQUIRED TO HAVE LICENSED DENTISTS.</li>
        </ul>
        <p>CHANGES TO THE SERVICES; NEW SERVICES; ADDITIONAL TERMS</p>
        <p>Changes to the Services; New Services</p>
        <p>
          We may from time to time add new features to the Services, substitute
          a new service for one of the existing Services, or discontinue or
          suspend one of the existing Services. Under no circumstances will
          Teledental be liable for any suspension or discontinuation of any of
          the Services or portion thereof, and the use of new services will be
          governed by this Agreement.
        </p>
        <p>Additional Terms</p>
        <p>
          Some Services may have additional terms (including, without
          limitation, policies, guidelines, and rules) that will further govern
          your use of that particular Service, and supplement this Agreement. If
          you choose to register for, access or use any such Services, you may
          be presented with such additional terms, which may also be found in
          the Additional Terms. By using those Services, you agree to comply
          with any such additional terms, which are incorporated by reference
          into this Agreement.
        </p>
        <p>LINKS TO OTHER WEBSITES</p>
        <p>
          {" "}
          While using the Services, you may encounter links to other websites.
          These links are provided for your convenience only and we do not
          endorse these sites or the products and services they provide. You
          acknowledge and agree that we are not responsible or liable for the
          content or accuracy of these other websites. Although we attempt to
          link only to trustworthy websites, it is possible that they will
          contain materials that are objectionable, unlawful, or inaccurate and
          we will not be responsible or liable for the legality or decency of
          material contained in or accessed through such other websites.
        </p>
        <p>CONTENT YOU POST OR SUBMIT</p>

        <p>
          You will have the opportunity to submit feedback regarding your
          experiences with Healthcare Providers you find through the Services,
          to submit inquiries concerning possible medical needs and to
          participate in the other interactive or community features of the Site
          (collectively, “Posted Information”). It is important that you act
          responsibly when providing Posted Information. Your Posted Information
          must comply with our Acceptable Use Policy, and your Healthcare
          Provider reviews must comply with our guidelines for reviews that
          state that no profanity or unfair content will be published.
        </p>
        <p>
          Teledental reserves the right to investigate and, at our discretion,
          take appropriate legal action against anyone who violates these Terms
          of Use or the Acceptable Use Policy, including without limitation,
          removing any offending communication from the Services and terminating
          the account of such violators or blocking your use of the Services.
        </p>
        <p>
          By posting Posted Information through the Services, you agree to and
          hereby do grant, and you represent and warrant that you have the right
          to grant, to Teledental and its contractors an irrevocable, perpetual,
          royalty-free, fully sublicensable, fully paid up, worldwide license to
          use, copy, publicly perform, digitally perform, publicly display, and
          distribute such Posted Information and to adapt, edit, translate,
          prepare derivative works of, or incorporate into other works, such
          Posted Information. This license is non-exclusive, except you agree
          that Teledental shall have the exclusive right to practice this
          license to the extent of combining your Posted Information with the
          Posted Information of other Teledental users for purposes of
          constructing or populating a searchable database of reviews and
          information related to the healthcare industry.
        </p>

        <p>YOUR USE OF CONTENT</p>
        <p>
          All of the Content is owned by us or our licensors and is protected by
          copyright, trademark, patent, and trade secret laws, other proprietary
          rights, and international treaties. You acknowledge that the Services
          and any underlying technology or software used in connection with the
          Services contain Teledental proprietary information. We give you
          permission to use the Content for personal, non-commercial purposes
          only and do not transfer any intellectual property rights to you by
          virtue of permitting your use of the Services. You may print,
          download, and store information from the Site for your own
          convenience, but you may not copy, distribute, republish (except as
          permitted in this paragraph), sell, or exploit any of the Content, or
          exploit the Site or Services in whole or in part, for any commercial
          gain or purpose whatsoever. Except as expressly provided herein,
          neither Teledental nor its suppliers grant you any express or implied
          rights, and all rights in the Site and the Services not expressly
          granted by Teledental to you are retained by Teledental.
        </p>
        <p>DISCLAIMER</p>

        <p>
          {" "}
          We created Teledental to improve patients’ healthcare experience, and
          we want your experience with Teledental to be exceptional. While we
          work hard to make that happen, you acknowledge that we have no control
          over, and no duty to take any action regarding: (a) which users gain
          access to the Site and/or the Services, (b) what Content you access,
          (c) what effects the Content may have on you, (d) how you may
          interpret or use the Content, or (e) what actions you may take as a
          result of having been exposed to the Content. You release us from all
          liability for you having acquired, you having not acquired, or your
          use of Content. We make no representations or warranties regarding
          suggestions or recommendations of services or products offered or
          purchased through the Site and/or the Services. We have no special
          relationship with or fiduciary duty to you. WE PROVIDE THE SERVICES
          “AS IS” AND “AS AVAILABLE.” WE MAKE NO EXPRESS OR IMPLIED WARRANTIES
          OR GUARANTEES ABOUT THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY
          LAW, WE HEREBY DISCLAIM ALL SUCH WARRANTIES, INCLUDING ALL STATUTORY
          WARRANTIES, WITH RESPECT TO THE SERVICES AND THE SITE, INCLUDING,
          WITHOUT LIMITATION, ANY WARRANTIES THAT THE SERVICES ARE MERCHANTABLE,
          OF SATISFACTORY QUALITY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR
          NEED, OR NON-INFRINGING. WE DO NOT GUARANTEE THAT THE RESULTS THAT MAY
          BE OBTAINED FROM THE USE OF THE SERVICES WILL BE EFFECTIVE, RELIABLE
          OR ACCURATE OR WILL MEET YOUR REQUIREMENTS. WE DO NOT GUARANTEE THAT
          YOU WILL BE ABLE TO ACCESS OR USE THE SERVICES (EITHER DIRECTLY OR
          THROUGH THIRD-PARTY NETWORKS) AT TIMES OR LOCATIONS OF YOUR CHOOSING.
          WE ARE NOT RESPONSIBLE FOR THE ACCURACY, RELIABILITY, TIMELINESS OR
          COMPLETENESS OF INFORMATION PROVIDED BY USERS OF THE SERVICES OR ANY
          OTHER DATA OR INFORMATION PROVIDED OR RECEIVED THROUGH THE SERVICES.
          EXCEPT AS EXPRESSLY SET FORTH HEREIN, TELEDENTAL MAKES NO WARRANTIES
          ABOUT THE INFORMATION SYSTEMS, SOFTWARE AND FUNCTIONS MADE ACCESSIBLE
          THROUGH THE SERVICES OR ANY OTHER SECURITY ASSOCIATED WITH THE
          TRANSMISSION OF SENSITIVE INFORMATION. TELEDENTAL DOES NOT WARRANT
          THAT THE SITE OR THE SERVICES WILL OPERATE ERROR-FREE, BUG-FREE OR
          FREE FROM DEFECTS, THAT LOSS OF DATA WILL NOT OCCUR, OR THAT THE
          SERVICES, SOFTWARE OR SITE ARE FREE OF COMPUTER VIRUSES, CONTAMINANTS
          OR OTHER HARMFUL ITEMS.
        </p>
        <p> GENERAL LIMITATION OF LIABILITY</p>
        <p>
          {" "}
          WHILE WE’RE ALWAYS IMPROVING, AND WE HOPE YOU HAVE AN EXCELLENT
          EXPERIENCE WITH TELEDENTAL, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY
          DISPUTE WITH US IS THE CANCELLATION OF YOUR ACCOUNT. IN NO EVENT SHALL
          OUR CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS RELATING TO OR
          ARISING OUT OF YOUR USE OF THE SERVICES OR THE SITE, REGARDLESS OF THE
          FORM OF ACTION, EXCEED THE GREATER OF (a) THE TOTAL AMOUNT OF FEES, IF
          ANY, THAT YOU PAID TO CREATE OR MAINTAIN AN ACCOUNT WITH THE SITE OR
          THE SERVICES, OR (b) $100; EXCEPT THAT, FOR ANY AND ALL CLAIMS
          RELATING TO OR ARISING OUT OF YOUR USE OF THE TRANSACTION PROCESSING
          SERVICES (AS DEFINED IN THE ADDITIONAL TERMS), IN NO EVENT SHALL OUR
          CUMULATIVE LIABILITY THEREOF REGARDLESS OF THE FORM OF ACTION EXCEED
          THE TOTAL AMOUNT OF TRANSACTION PROCESSING FEES (AS DESCRIBED IN THE
          ADDITIONAL TERMS), IF ANY, REMITTED TO AND RETAINED BY TELEDENTAL FOR
          PROVIDING TRANSACTION PROCESSING SERVICES FOR APPOINTMENTS MADE BY YOU
          IN THE SIX (6) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE APPLICABLE
          CLAIM.
        </p>

        <p>
          This Agreement does not entitle You to any support for the Licensed
          Materials unless You make separate arrangements with Teledental.com
          for such support. Any such support provided by Teledental.com shall be
          subject to the terms of this Agreement as modified by a separate
          support agreement. You are solely responsible for providing all
          support and technical assistance to end-users of the Licensed
          Applications. You acknowledge and agree that Teledental.com has no
          obligation to provide support or technical assistance directly to Your
          end-users and You shall not represent to any of its end users that
          Teledental.com is available to provide such support.
        </p>
        <p>
          <strong>Privacy:</strong>
        </p>

        <ul>
          <li>
            Teledental.com User Information. To the extent, you have access to
            and are permitted to use Teledental.com User Information through the
            Licensed Materials, such access and use shall be in accordance with
            the then-current Teledental.com Privacy Policy. You shall make
            reasonable efforts to ensure that Teledental.com User Information
            disclosed in Content, inadvertently or otherwise, is not exploited
            for improper purposes such as identity theft, fraud or spamming.
          </li>
          <li>
            Your User Information. You may collect information relating to users
            of Your Applications ("Your Application User Information"). If You
            collect Your Application User Information, You shall provide to
            users an applicable privacy policy that clearly and accurately
            discloses Your information collection, storage and sharing
            practices, including the extent to which such Your Application User
            Information is disclosed to third parties such as Teledental.com.
            Your privacy policy must: (i) comply and be consistent with all
            applicable laws, including but not limited to data protection
            regulations in applicable territories; (ii) allow disclosure of Your
            Application User Information to Teledental.com, to the extent
            necessary for Your use of the Teledental.com API and other Licensed
            Materials, in accordance with the then-current Teledental.com
            Privacy Policy; and (iii) be no less protective of applicable end
            users than the then-current Teledental.com Privacy Policy.
          </li>
        </ul>

        <p>
          This Agreement does not entitle You to any support for the Licensed
          Materials unless You make separate arrangements with Teledental.com
          for such support. Any such support provided by Teledental.com shall be
          subject to the terms of this Agreement as modified by a separate
          support agreement. You are solely responsible for providing all
          support and technical assistance to end-users of the Licensed
          Applications. You acknowledge and agree that Teledental.com has no
          obligation to provide support or technical assistance directly to Your
          end-users and You shall not represent to any of its end users that
          Teledental.com is available to provide such support.
        </p>
        <p>
          <strong>MISCELLANEOUS</strong>
        </p>

        <p>
          <strong>Electronic Contracting; Copyright Dispute</strong>
        </p>
        <p>
          Your affirmative act of using the Services and/or creating an account
          constitutes your electronic signature to this Agreement, which
          includes our Privacy Policy, Acceptable Use Policy and Additional
          Terms, and your consent to enter into such agreements with us
          electronically.
        </p>
        <p>
          Please review our Acceptable Use Policy for our copyright dispute
          policy.
        </p>

        <p>
          <strong>
            Choice of Law; Arbitration Clause and Class Action Waiver –
            Important – Please Review as This Affects Your Legal Rights
          </strong>
        </p>

        <p>
          We hope that we can work out any disagreements you might have with
          Teledental. But if there is a dispute that needs to be further
          resolved, that process will take place according to this section. This
          Agreement shall be governed by the laws of Australia and any
          controversies, differences or disputes arising out of or in connection
          with this agreement shall be resolved amicably between the parties who
          are subject to the jurisdiction of Delaware courts.
        </p>
        <p>
          <strong>Severability</strong>
        </p>

        <p>
          In the event any one or more of the provisions contained in this
          Agreement shall for any reason be held invalid, illegal, or
          unenforceable in any respect, that invalidity, illegality, or
          unenforceability shall not affect any other provisions. This Agreement
          shall be construed as if the invalid, illegal, or unenforceable
          provision had never been contained in it.
        </p>
        <p>
          <strong>Entire Agreement</strong>
        </p>
        <p>
          This Agreement and any supplemental terms, policies, rules, and
          guidelines posted through the Services, each of which is incorporated
          herein by reference, including the Privacy Policy and the Acceptable
          Use Policy, constitute the entire agreement between you and us and
          supersede all previous written or oral agreements. If any part of this
          Agreement is held invalid or unenforceable, that portion shall be
          construed in a manner consistent with applicable law to reflect, as
          nearly as possible, the original intentions of the parties, and the
          remaining portions shall remain in full force and effect. The failure
          of Teledental.com to exercise or enforce any right or provision of
          this Agreement shall not constitute a waiver of such right or
          provision. The failure of either party to exercise in any respect any
          right provided for herein shall not be deemed a waiver of any further
          rights hereunder.
        </p>

        <p>
          <strong>Eligibility</strong>
        </p>

        <p>
          To create an account in Teledental.com and utilize our services, you
          must be 18 years and over, or the required legal age in your area of
          jurisdiction. We have however opened a window for the age between
          13-18 years. If you are to use our site, you must work under close
          supervision from your parent or guardian who has agreed to abide by
          the Terms of Use. For those under 13, they may not use the site or the
          services offered. By using the Site or Services on behalf of a minor
          child, you represent and warrant that you are the parent or legal
          guardian of such child and that all references in these Terms of Use
          to “you” shall refer to such child or such other individual on whose
          behalf you have the authorization to enter into these Terms of Use and
          you in your capacity as the parent or legal guardian of such child or
          as the authorized party of such individual. We do update this
          agreement from time to time.{" "}
          <strong>
            We reserve the right to amend or change the terms of use and privacy
            policy as needed. WE DO RESERVE THE RIGHT TO REFUSE SERVICE TO
            PEOPLE WHO WE FEEL ARE NOT ABLE TO UNDERSTAND OR ABIDE BY OUR TERMS
            AND CONDITIONS.
          </strong>
        </p>

        <p>
          <strong>NO DISCRIMINATION:</strong>
        </p>

        <p>
          <strong>
            WE DO NOT REFUSE SERVICE BASED ON ANY KIND OF GENDER, RACIAL OR
            RELIGIOUS DISCRIMINATION. WE LIKE TO TREAT ALL FAIRLY.
          </strong>
        </p>

        <p>
          <strong>
            NOTHING INAPPROPRIATE: IF A PERSON DOES USE INAPPROPRIATE LANGUAGE,
            STARTS TO ACT INAPPROPRIATELY, SEXUAL - OUR DENTIST DO HAVE THE
            RIGHT TO DISCONTINUE SERVICE. WE DO NOT GUARANTEE ANY REFUND WILL BE
            PROVIDED. WE WILL TAKE EACH CASE CONSIDERATION ON ITS OWN MERIT. IF
            FEEL ANYTHING IS INAPPROPRIATE - PLEASE EMAIL US AT{" "}
            <a href="mailto:service@teledental.com">SERVICE@TELEDENTAL.COM</a>.
          </strong>
        </p>

        <p>
          <strong>
            WE RESERVE THE RIGHT TO MONITOR ANY CONVERSATIONS OR DENTAL VIDEO
            CONSULTS - SO, CAN PROVIDE BETTER SERVICE AND MAKE SURE SERVICE IS
            WORKING PROPERLY. IF YOU FEEL SOMETHING IS NOT RIGHT, YOU CAN ALWAYS
            DISCONTINUE THE DENTAL VIDEO CALL.
          </strong>
        </p>

        <p>
          <strong>
            Last update was 10/20/2020. We reserve the right to change or to
            modify the Terms and Conditions at any time.
          </strong>
        </p>
        <p>
          If you are not eligible with the terms do not utilize the Site or the
          Services offered therein. It will be void because it is prohibited by
          applicable law, and the right to access the site is revoked in such
          jurisdictions. When you use the site and the services, you guarantee
          that you have the capacity to enter into the Terms of Use. Please note
          that the site is meant for U.S. users only. Using it outside the U.S.
          is prohibited and at your own risk. Finally, the users should adhere
          to the local, state, or federal laws that are applicable to their use
          of the Site or the Services offered therein.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
