import React from "react";
import HelmetComponent from "./HelmetComponent";
import Header from "./Header";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <>
      <Header />

      <div
        style={{
          color: "#000",
          maxWidth: "900px",
          margin: "0px auto 10px auto",
        }}
        className="pt-96"
      >
        <HelmetComponent
          title="Privacy Policy | Teledental"
          name="Privacy Policy"
          content="ANY COMMUNICATION BY DENTIST OR TELEDENTAL ADMIN DONE WITH
                    USERS, IS SUBJECTIVE. WE DO <u>NOT</u> ENDORSE OR MANDATE
                    ANY DENTAL TREATMENT."
        />
        <p
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h1>Privacy Policy</h1>
        </p>
        <hr />
        <p>
          <strong>
            <u>PRIVACY POLICY - TELEDENTAL.COM</u>
          </strong>
        </p>
        <p>
          <strong>WELCOME TO TELEDENTAL -</strong>
        </p>
        <p>
          <strong>
            PRIVACY POLICY: THIS IS THE PRIVACY POLICY OF{" "}
            <Link to="https://teledental.com/">TELEDENTAL.COM</Link>
          </strong>
        </p>
        <p>
          <strong>
            ANY COMMUNICATION BY DENTIST OR TELEDENTAL ADMIN DONE WITH USERS, IS
            SUBJECTIVE. WE DO NOT ENDORSE OR MANDATE ANY DENTAL TREATMENT. WE
            ONLY PROVIDE A DENTAL SUGGESTION, SINCE WE ARE NOT PRIVY TO ALL THE
            RELEVANT INFORMATION. IF YOU AS A USER ARE LOOKING FOR A FULL DENTAL
            EXAM OR THOROUGH DENTAL EXAM, YOU SHOULD GO TO A LOCAL DENTAL
            OFFICE. WE AT TELEDENTAL.COM OR TELEDENTAL APP ONLY PROVIDE DENTAL
            SUGGESTIONS, THAT MAY OR MAY NOT BE USEFUL
          </strong>
        </p>
        <p>
          <strong>
            ANY LIVE DENTAL VIDEO CONSULTATION IS SUBJECTIVE AND ALL PATIENTS
            ARE ADVISED TO GET FURTHER IN-OFFICE FULL DENTAL EXAMINATION. MANY
            DENTAL ILLNESSES WILL NEED IN-OFFICE DENTAL IMAGING X-RAYS.
          </strong>
        </p>
        <p>
          The terms Teledental, Teledental.com, Teledental inc. and we are used
          interchangeably. Teledental inc. is growing and looking to help people
          get more dental information. Anyone using our services understands
          that dentistry is subjective and that each individual needs to do
          their own due diligence. Anyone not agreeing to our terms and
          conditions and the limitations in teledental services, should not use
          teledental.com or teledental app. Anytime using an online website and
          app, there is a possibility of hacking. We try our best to be secure,
          but anyone using our services should understand those risks.{" "}
        </p>
        <p>
          <strong>
            WE VALUE YOUR PRIVACY. WE DO COLLECT DATA LIKE MANY OF THE LEADING
            ONLINE SEARCH AND SOCIAL MEDIA SITES. PART OF THE REASON WE COLLECT
            DATA IS TO BETTER SERVE YOU THE CONSUMER, THE LOCAL BUSINESSES AND
            US. WE LIMIT THIS. WE HAVE MODIFIED AND USE MANY STANDARD ONLINE
            RULES. BY USING OUR SITE, YOU ACCEPT OUR PRIVACY POLICY{" "}
            <u>
              WE RESERVE ALL RIGHTS NOT EXPRESSLY WRITTEN HERE TO MODIFY OR
              CHANGE AT OUR SOLE DISCRETION AT ANYTIME . WE RESERVE ALL RIGHTS
              TO CHANGE OR MODIFY THESE TERMS AT ANYTIME.
            </u>{" "}
          </strong>
        </p>
        <p>
          <>
            BY USING OUR SITE, YOU HEREBY CONSENT TO OUR PRIVACY POLICY AND
            AGREE TO THE TERMS AND CONDITIONS.
          </>
        </p>
        <p>
          <strong>1. DATA</strong>
        </p>
        <p>
          Data you provide and certain other information about you is subject to
          our Privacy Policy. We try to limit who we share our data with and
          value our users' privacy. We do collect data and have cookies, like
          many internet sites to better assist us in providing services to you
          and the local businesses that may cater to your needs. The data allows
          us to better administer accounts, to provide promotional Dines and
          services. We do try to control and limit this to what is necessary to
          make us an online premium Dine, discussion and search source.
        </p>
        <p>
          <strong>2. TELEDENTAL, CONTENT, AND PERMISSION</strong>
        </p>
        <p>
          ALL TELEDENTAL posted on any of our sites, HAVE to be first verified
          at the business PRIOR to use, as some businesses change their
          promotions at will and may not notify us. Like all sites, there may be
          hacking or errors in content. We do not assume any liability in case
          of content error. We do recommend the user share with us any content
          error or omission. We get a lot of emails and spam and do not
          guarantee any response. We do try to keep these accurate and in use,
          but errors may occur and agree to hold “Teledental” harmless for any
          misunderstanding / errors with Dines, content of forum, or ads placed.
          You agree that you will not use any robot, spider, other automatic
          device or manual process to monitor or copy the Services or the
          content contained therein without our prior express written consent.
          You agree that you will not use any device, software, or routine to
          interfere or attempt to interfere with the proper working of the
          Services or any activities conducted on the Services. You agree that
          you will not copy, reproduce, alter, modify, or create derivative
          works from the Services without the prior express written consent of
          Teledental.
        </p>
        <p>
          User Content is created by users in the course of using the website.
          This includes but is not limited to users’ information about
          themselves or job openings and may be visible to other users.
          Teledental does not endorse, and specifically disclaims any
          responsibility or liability for, any User Content. By using the
          Services, you agree to be bound by the following terms and conditions.
          If you do not want to be bound by these terms, then do not use the
          Services. You understand that all information, data, text, software,
          music, sound, photographs, graphics, video, messages or other
          materials whether publicly posted or privately transmitted, are the
          sole responsibility of the person from which such User Content
          originated. This means that you, and not Teledental, are entirely
          responsible for all User Content that you upload, post, email,
          transmit or otherwise make available via the Services. Teledental does
          not control or actively monitor the User Content posted by users and,
          as such, does not guarantee the accuracy, integrity or quality of such
          User Content. You understand that by using the Services, you may be
          exposed to User Content that is offensive, indecent or objectionable.
          Under no circumstances will Teledental be liable in any way for any
          User Content, including, but not limited to, for any errors or
          omissions in any User Content, or for any loss or damage of any kind
          incurred as a result of the use of any User Content posted, emailed,
          transmitted or otherwise made available via the Services. You
          understand that Teledental cannot guarantee the identity of any other
          users with whom you may interact in the course of using the Services.
          Additionally, Teledental cannot guarantee the authenticity of any data
          which users may provide about themselves. We do not fully validate any
          reviews, and at times may take out unfounded or profanity harassing
          reviews at our discretion. Data collected or listings categories or
          types of listings maybe changed at our discretion. If a business
          subscribes with us, and we change the site – then an equivalent type
          of listing will be substituted. We cannot ever guarantee top listing
          for any business, as settings change for our sites occasionally. Hence
          all businesses that subscribe to us, understand that change in where
          and how they are placed can happen.
        </p>
        <p>
          <strong>3. POSTING, ADVERTISING, USING OUR SITES</strong>
        </p>
        <p>
          By posting, AND advertising – we may highlight your posting, or
          advertisement on the front page, and may use it in our advertisements.
        </p>
        <p>
          COOKIES - TELEDENTAL USES COOKIES TO BETTER SERVE OUR USERS. THIS
          INCLUDES TO BETTER CONNECT OUR USERS TO LOCAL DENTISTS AND DENTAL
          OFFICES IN THEIR AREA. WE ALSO USE SOME GOOGLE COOKIES TO BETTER SERVE
          AND UNDERSTAND OUR USERS.
        </p>
        <p>
          WE DO NOT SELL DATA TO ANY BUSINESSES AND HAVE NOT DONE SO FOR THE
          LAST SEVERAL YEARS. THIS IS AS OF 12/10/2019. THIS MAY CHANGE IN THE
          FUTURE, THOUGH IT IS CURRENTLY NOT PLANNED. WE RESPECT OUR USERS
          PRIVACY.
        </p>
        <p>
          <strong>4. Conduct</strong>
        </p>
        <p>
          You agree to be fully responsible for your own conduct and User
          Content and agree to access and use the Services at your own risk on
          an as-is basis. While Teledental has no obligation to monitor the
          Services, it may, from time to time, monitor the Services to determine
          whether their use complies with these Terms of Use. Teledental cannot
          and does not guarantee that it will continue to post all User Content
          you or other users submit to the Services. Also, Teledental reserves
          the right to edit or abridge User Content for any reason and to
          disclose any information as necessary to satisfy any applicable law,
          regulation, legal process or governmental request, or to edit, refuse
          to post or remove any information or materials, in whole or in part,
          in Teledental sole discretion. For business reviews or ratings, we
          leave open and try to keep it civil. Anything deemed inaccurate,
          please share with us – though with all the emails we get and the spam
          we get – a response is not guaranteed. Any reviews that we find
          offensive, at our sole discretion we will remove.
        </p>
        <p>
          ALL USERS OF THIS SITE SHOULD USE THIS SITE WITH COMMON SENSE. OUR
          GOAL IS TO HAVE THE BEST SITE THAT HELPS PEOPLE ALL OVER. WE HAVE MANY
          THOUSANDS OF USERS WHO SEEM TO BE HAPPY AT WHAT WE HAVE SUGGESTED TO
          THEM. THOUGH, ERRORS ON OUR SITE DO OCCUR TIME TO TIME. ANY DENTAL
          EMERGENCY NEEDS TO BE SEEN AT A ER HOSPITAL OR DENTAL OFFICE NEAR YOU
          AS SOON AS POSSIBLE. WITHOUT A FULL DENTAL IN OFFICE EXAM AND XRAYS,
          OUR CONSULT IS MEANT ONLY AS SUPPLEMENTAL INFORMATION THAT MAY OR MAY
          NOT BE ACCURATE. WE DO NOT ENDORSE ANY DENTISTS OR DENTAL VIEWS, OR
          DENTAL OFFICE(S) CONSULTATION, DENTAL ARTICLE, DENTAL SUGGESTION,
          DENTIST TREATMENT SUGGESTION OR DENTIST CONSULTATION OR DENTIST
          TREATMENT PLAN POSTED ON HERE. EVERYTHING STATED HERE SHOULD BE
          VERIFIED AND DO THOROUGH IN DENTAL OFFICE EXAMINATION AND DENTAL XRAYS
          TO KNOW EXACTLY WHAT IS GOING ON.
        </p>
        <p>
          INFORMATION ON THIS SITE PROVIDED TO THE USER IS MEANT AS SUPPLEMENTAL
          - AND MAY OR MAY NOT BE ACCURATE, AS WE CAN NOT SEE FULL MEDICAL
          INFORMATION / BACKGROUND OF USER AND WE DO NOT TAKE ALL THE REQUIRED
          XRAYS AND WE DO NOT DO AN IN-PERSON THOROUGH DENTAL EXAM. HENCE,
          ERRORS MAY OCCER - THOUGH WE TRY OUR BEST TO BE HELPFUL AND TRY TO
          PROVIDE USEFUL INFORMATION. WE HAVE 3RD PARTY DENTAL OFFICES IN
          VARIOUS LOCATIONS THAT USE OUR SERVICE TO HELP OUR USERS. WE DO NOT IN
          ANY WAY ENDORSE OR ARE RESPONSIBLE FOR THEIR TREATMENT PLAN OR HOW
          THEY TREAT THE USER. HENCE, ANY USER CONNECTED TO A DENTAL OFFICE OR
          DENTIST ON TELEDENTAL.COM - NEEDS TO DO THEIR OWN DUE DILIGENCE. WE IN
          NO WAY ENDORSE OR KNOW THE TREATMENT PLAN THEY WILL BE PROPOSING AND
          DO NOT KNOW DETAILS OF THE DENTIST(S) SKILL SET. WE AT TELEDENTAL.COM
          TAKE NO RESPONSIBILITY ON ANY TREATMENT OR SERVICE PROVIDED BY ANY
          DENTIST OR DENTAL OFFICE. WE DO NOT ENDORSE ANY DENTIST OR DENTAL
          OFFICE - THEY USE OUR SERVICE, BUT WE HAVE NO WAY OF CHECKING HOW GOOD
          THEY ARE OR WHAT TREATMENT PLAN THEY PROPOSE OR HOW THEY CHARGE THE
          PATIENT, ETC. HENCE, ANY USER OF OUR SITE UNDERSTANDS THIS FULLY AND
          IS ASSUMED TO HAVE COMMON SENSE. PLEASE DO NOT SHARE ANY INFORMATION
          ON THIS SITE, THAT YOU DO NOT WANT TO BE ONLINE. ANY ONLINE SITE, CAN
          BE HACKED - AS HARD AS WE TRY TO NOT LET THAT HAPPEN. WE SHARE YOUR
          INFORMATION TO DENTISTS IN YOUR LOCAL AREA, SO THEY CAN HELP YOU. WE
          MOSTLY LIMIT ACCESS TO DENTISTS AND DENTAL OFFICES IN OUR NETWORK. OUR
          GOAL IS TO HELP THE USER HAVE THEIR DENTAL QUESTION ANSWERED AND HELP
          THEM FIND DENTAL SERVICE. YOU AS PATIENT ALWAYS HAVE FULL CONTROL
          WHETHER WANT TO SEE A DENTIST OR DENTAL OFFICE, OR NOT. WE ENCOURAGE
          ALL OUR USERS TO USE COMMON SENSE AND TO SEE WHAT IS THEIR BEST
          OPTION.
        </p>
        <p>
          <u>NON-DISCRIMINATION</u>: WE WILL NOT DISCRIMINATE AGAINST YOU FOR
          USING ANY OF YOUR CCPA RIGHTS. WE DO NOT DISCRIMINATE BASED ON RACE,
          ETHNICITY, RELIGION OR GENDER.
        </p>
        <p>
          WE HOPE WE CAN HELP AND TO PROVIDE SOME OPTIONS FOR YOUR DENTAL CARE
          NEEDS. ALL USERS SHOULD DO THEIR OWN DISSEMINATION OF ANY SUGGESTIONS
          OR ANSWERS THEY ARE GIVEN WITH US. WE @ TELEDENTAL DO NOT CLAIM
          ACCURACY OF ANY INFORMATION GIVEN ON THIS SITE. OUR GOAL IS TO PROVIDE
          AS GOOD INFORMATION AS POSSIBLE, BUT INACCURACIES AND DIFFERING OF
          OPINIONS IS ALWAYS THE CASE. WE TRY TO HAVE GOOD DENTISTS ON OUR LIST,
          WE DO NOT ENDORSE OR VERIFY THE SKILL SET OF ANY DENTISTS OR DENTAL
          PRACTICES WE LIST. ALL OF OUR USERS SHOULD DO THEIR OWN DUE DILIGENCE.
          ALL THOSE TAKING MEDICATIONS SHOULD DEFINITELY CHECK WITH THEIR
          PHYSICIANS FIRST. MEDICATIONS CAN HAVE SIDE-EFFECTS SUCH AS ALLERGIES,
          ETC. WE DO USE SOME Ai / ARTIFICIAL INTELLIGENCE - LIKE ANY SOFTWARE,
          ERRORS OR MIS-STATEMENTS CAN BE MADE WITH THIS SOFTWARE. PLEASE DOUBLE
          CHECK ALL INFORMATION - ANY INFORMATION IS ONLY A SUGGESTION & NEEDS
          TO BE VERIFIED WITH A LICENSED DENTIST. WE CURRENTLY DO NOT ASK FOR
          ANY PAYMENT BY THE USER OR PATIENT ON OUR SITE, FOR ANSWERING DENTAL
          QUESTION(S), ETC. IF FEEL OUR SITE IS BEING HACKED OR MISUSED, PLEASE
          LET US KNOW. WE TRY OUR BEST TO WATCH WHAT IS HAPPENING ON
          TELEDENTAL.COM
        </p>
        <p>
          - Agree to Not improperly collect or store personal data about users.
        </p>
        <p>
          - Not to harass, harm others. Including harming minors in any way,
          elderly or disabled.
        </p>
        <p>- Not to solicit or contact minors.</p>
        <p>
          - Not Posting inappropriate content in fields that are not intended
          for that content. This includes posting email addresses, etc – to
          spam.
        </p>
        <p>
          - Not to Post, upload, email, transmit or otherwise make available any
          User Content that is unlawful, harmful, threatening, abusive,
          harassing, “stalk,” defamatory, vulgar, obscene, libelous, invasive of
          person’s privacy, racially, ethnically, hateful or otherwise
          objectionable;
        </p>
        <p>
          - Not to Post, upload, email, transmit or otherwise make available any
          unsolicited or unauthorized advertising, promotional materials, “junk
          mail,” “spam,” “chain letters,” “pyramid schemes,” or any other form
          of solicitation, or any contests or chain letters.
        </p>
        <p>
          - Not to Interfere with or disrupt the Services or servers or networks
          connected to the Services. This would include disobeying any
          requirements, procedures, policies or regulations of networks
          connected to the Services.
        </p>
        <p>
          - Not to Manipulate identifiers or forge headers in order to disguise
          the origin of any User Content transmitted through the Services;
        </p>
        <p>
          - Please use our site to gain information, and use proper common
          sense.
        </p>
        <p>
          You acknowledge that Teledental may or may not pre-screen User
          Content, but that Teledental and its designees shall have the right
          (but not the obligation) in their sole discretion to pre-screen,
          refuse, or move any User Content that is available via the Services.
          Without limiting the foregoing, Teledental and its designees shall
          have the right to remove any User Content. You agree that you must
          evaluate, and bear all risks associated with, the use of any User
          Content, including any reliance on the accuracy, completeness, or
          usefulness of such User Content.
        </p>
        <p>
          You acknowledge, consent and agree that Teledental may access,
          preserve, and disclose your account information and User Content if
          required to do so by law or in a good faith belief that such access
          preservation or disclosure is reasonably necessary to: (a) comply with
          legal process; (b) enforce these Terms of Use; (c) respond to claims
          that any User Content violates the rights of third-parties; (d)
          respond to your requests; or (e) protect the rights, property, or
          personal safety of Teledental, its users and the public.
        </p>
        <p>
          You agree not to violate any applicable local, state, national or
          international law in connection with your use of the Services. This
          includes, among other things, any securities law or regulation.
        </p>
        <p>
          By submitting any User Content, posting a message, uploading a file or
          engaging in any other form of communication through the Services, you
          are granting Teledental a perpetual, royalty-free and irrevocable
          right and license to use, reproduce, modify, adapt, publish,
          translate, distribute, transmit, display, perform, sublicense, create
          derivative works based upon, transfer and sell any such User Content,
          messages, files or communications. Employers and their representatives
          also grant to Teledental and its affiliates a perpetual, royalty-free
          and irrevocable right and license to use, reproduce, communicate to
          the public and display the name and trademark of their organization
          and status as a contributor of User Content to Teledental. Employers
          and their representatives warrant and represent that they have the
          authority to enter into this license agreement and that they are the
          holder of any rights, including moral rights in such content and
          trademarks, and that they have completely and effectively waived all
          such rights and validly and irrevocably granted to Teledental the
          license stated above. Subject to the foregoing, the owner of such
          content and trademark submitted to Teledental retains any and all
          rights that may exist in such content and trademarks.
        </p>
        <p>
          You agree to indemnify and hold Teledental and its affiliates and
          their respective officers, directors, and employees harmless from any
          claims, damages, losses or costs (including reasonable attorney’s
          fees) that arise out of your use of the Services and any User Content
          provided by you to the Services.
        </p>
        <p>
          <strong>5. CANCELLATION AND REFUNDS.</strong>
        </p>
        <p>
          Teledental offers a variety of subscription plans for businesses at
          varying price levels. If you have purchased a subscription, it will
          automatically renew unless canceled prior to your renewal date. You
          may cancel your subscription at any time and for any reason.
        </p>
        <p>
          Fees paid for subscriptions will not be refunded or pro-rated when you
          cancel your subscription. Where applicable and unless otherwise set
          forth in your specific plan details, your account will remain active
          until the end of your current subscription term unless otherwise
          terminated by Teledental. Subscription fees charged to your account
          after receipt of your cancellation will be refunded. If Teledental
          notices irregularities, spamming or incorrect information – it may
          cancel your account at its choosing. Our goal is to have the gold
          standard of service and satisfaction.
        </p>
        <p>
          To cancel your subscription, please contact us at
          signup@Teledental.com or info@Teledental.com.
        </p>
        <p>
          <strong>6. TERMINATION.</strong>
        </p>
        <p>
          You may terminate your Teledental account at any time and for any
          reason. Any notice to Teledental which shall be effective upon
          Teledental processing such notice. We get a tremendous amount of email
          and mail – hence, we recommend reaching out to us again, if you do Not
          hear anything from us within 7 to 10 business days. We reserve the
          right to keep a business listing up, as most businesses' information
          is public knowledge. You agree that Teledental, in its sole
          discretion, may terminate your password, account (or any part thereof)
          or use of the Services, and remove and discard any content within the
          Services, for any reason, including without limitation, for lack of
          use or if Teledental believes that you have violated these Terms of
          Use or have not acted consistently with the spirit of these Terms of
          Use. Teledental may also in its sole discretion and at any time
          discontinue providing the Services under, or any part thereof, with or
          without notice. You agree that any termination of your access to the
          Services under any provision of these Terms of Use may be effected
          without prior notice, and acknowledge and agree that Teledental may
          immediately deactivate or delete your account and all related
          information and files in your account and bar any further access to
          such files or the services provided in the Services. Further, you
          agree that Teledental shall not be liable to you or any third-party
          for any termination of your access to the services.
        </p>
        <p>
          Subscription fees for Teledental products will not be refunded or
          pro-rated for partial-month or partial-year usage upon termination.
        </p>
        <p>
          THERE MAY BE INACCURATE INFORMATION ON TELEDENTAL.COM – WE ARE NOT
          RESPONSIBLE FOR ANY INFORMATION IF THAT HAPPENS. WE RECOMMEND ALL
          PEOPLE GET PROFESSIONAL DENTAL CONSULTATION BY A DENTIST BEFORE MAKING
          ANY DECISION.
        </p>
        <p>
          <strong>7. DISCLAIMER OF WARRANTIES AND LIABILITY.</strong>
        </p>
        <p>
          Due to the number of sources from which the Content is obtained, and
          the inherent hazards of electronic distribution, there may be delays,
          omissions or inaccuracies in such Content and the Services. THE
          CONTENT AND THE WEB SITE ARE PROVIDED “AS IS” AND “AS AVAILABLE”,
          WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED. TELEDENTAL AND ITS
          AFFILIATES, AGENTS AND LICENSORS CANNOT AND DO NOT MAKE ANY
          REPRESENTATION WITH RESPECT TO OR WARRANT THE ACCURACY, WHOLENESS,
          COMPLETENESS, CURRENTNESS, TIMELINESS, NONINFRINGEMENT, TITLE,
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE CONTENT
          AVAILABLE THROUGH THE WEB SITE, OR THE WEB SITE ITSELF. TELEDENTAL
          HEREBY DISCLAIMS ANY SUCH EXPRESS OR IMPLIED WARRANTIES. NEITHER
          TELEDENTAL NOR ANY OF ITS AFFILIATES, AGENTS OR LICENSORS WILL BE
          LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR INJURY, OTHER THAN DEATH
          OR PERSONAL INJURY RESULTING DIRECTLY FROM USE OF THE WEB SITE, CAUSED
          IN WHOLE OR PART BY ITS NEGLIGENCE OR CONTINGENCIES BEYOND ITS CONTROL
          IN PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING THE WEB
          SITE AND ANY CONTENT ON THE WEB SITE. IN NO EVENT WILL TELEDENTAL, ITS
          AFFILIATES, AGENTS OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR
          ANY DECISION MADE OR ACTION TAKEN BY YOU IN RELIANCE ON SUCH CONTENT
          OR THE WEB SITE. TELEDENTAL AND ITS AFFILIATES, AGENTS AND LICENSORS
          SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES (INCLUDING,
          WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT OR
          SIMILAR DAMAGES), OTHER THAN DIRECT DAMAGES, EVEN IF ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO
          NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR DAMAGES OR THE
          EXCLUSION OF CERTAIN TYPES OF WARRANTIES, PARTS OR ALL OF THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU.
        </p>
        <p>
          <strong>8. LINKS TO THIRD PARTY SITES.</strong>
        </p>
        <p>
          The Services contain hyperlinks to web sites operated by persons other
          than Teledental. Such hyperlinks are provided for your reference and
          convenience only. You agree not to hold Teledental responsible for the
          content or operation of such web sites. A hyperlink from a Service to
          another web site does not imply or mean that Teledental endorses the
          content on that web site or the operator or operations of that site.
          You are solely responsible for determining the extent to which you may
          use any content at any other web sites to which you might link from a
          Service. WE MAY USE COOKIES TO BETTER UNDERSTAND YOUR LOCATION, SO AS
          TO BETTER SERVE OUR USERS AND BETTER CONNECT THEM TO LOCAL DENTISTS
          AND DENTAL OFFICES.
        </p>
        <p>
          If you wish to make purchases from an area of the Services or a
          “co-branded” website operated by a person other than Teledental (a
          “Co-Branded Site”), you may be asked by the merchant or operator of
          the Co-Branded Site or other person from whom you are making the
          purchase to supply certain information, including credit card, debit
          card or other payment mechanism information. You agree not to hold
          Teledental liable for any loss or damage of any sort incurred as a
          result of any such dealings with any Co-Branded Site or merchant or
          operator of the Co-Branded Site or other people from whom you are
          making the purchase. You agree that all information you provide in
          connection with such purchase will be accurate, complete and current.
          You agree to pay all charges incurred by users of your credit card,
          debit card or other payment mechanisms at the prices in effect when
          such charges are incurred. You also will pay any applicable taxes, if
          any, relating to any purchases you make. Each Co-Branded Site will
          contain a description of the site’s privacy policy regarding any
          personal identifying information you may be required to disclose as
          part of the purchase.
        </p>
        <p>
          <>
            WE VALUE YOUR PRIVACY. ALL TERMS ARE SUBJECT TO CHANGE WITHOUT
            NOTICE. OUR GOAL IS TO HAVE THE GOLD STANDARD OF PRIVACY, CUSTOMER
            AND BUSINESS SERVICE. ON OCCASION WE MAY HAVE INACCURATE INFORMATION
            – PLEASE CHECK ALL DENTAL INFORMATION FOR ACCURACY WITH A DENTIST &
            DENTAL PROFESSIONAL AT A DENTAL OFFICE. ALL SUGGESTIONS SHOULD BE
            FULLY REVIEWED WITH A DENTIST AT A DENTAL OFFICE. OUR GOAL IS TO
            PROVIDE SOME INFORMATION AND TO CONNECT PATIENTS WITH A DENTIST.
          </>
        </p>
        <p>
          <strong>9. ADDITIONAL LEGAL TERMS.</strong>
        </p>
        <p>
          <>
            <u>RECORDINGS - TELEDENTAL - AUDIO - TELEDENTISTRY</u>: ANY
            COMMUNICATION DONE ON TELEDENTAL.COM OR TELEDENTAL APP MAY OR MAY
            NOT BE RECORDED. BY USING TELEDENTAL.COM OR TELEDENTAL APP, USERS
            ALLOW TELEDENTAL.COM OR TELEDENTAL APP TO RECORD THE AUDIO OR VIDEO
            CONSULT. HENCE, BY USING OUR SERVICES - YOU ALLOW TELEDENTAL.COM OR
            TELEDENTAL APP RECORD ANY VIDEO, CHAT, TELEDENTAL OR COMMUNICATION
            MESSAGING.
          </>
        </p>
        <p>
          <strong>WE FOLLOW HIPAA GUIDELINES:</strong>
        </p>
        <p>Security Rule</p>
        <p>
          The HIPAA Security Rule establishes national standards to protect
          individuals’ electronic personal health information that is created,
          received, used, or maintained by a covered entity. The Security Rule
          requires appropriate administrative, physical and technical safeguards
          to ensure the confidentiality, integrity, and security of electronic
          protected health information.
        </p>
        <p>The HIPAA Privacy Rule</p>
        <p>
          The HIPAA Privacy Rule establishes national standards to protect
          individuals' medical records and other personal health information and
          applies to health plans, health care clearinghouses, and those health
          care providers that conduct certain health care transactions
          electronically. The Rule requires appropriate safeguards to protect
          the privacy of personal health information, and sets limits and
          conditions on the uses and disclosures that may be made of such
          information without patient authorization. The Rule also gives
          patients rights over their health information, including rights to
          examine and obtain a copy of their health records, and to request
          corrections.
        </p>
        <p>
          If any provision in these Terms of Use is invalid or unenforceable
          under applicable law, the remaining provisions will continue in full
          force and effect, and the invalid or unenforceable provision will be
          deemed superseded by a valid, enforceable provision that most closely
          matches the intent of the original provision. Teledental.com /
          Teledental is currently a Delaware company. Any legal issues would be
          governed by the state of Delaware and would be administered in
          Delaware.
        </p>
        <p>
          <strong>
            What to do if you believe a person violates these Terms of Use:
          </strong>
          If you believe that a person or organization using the Services
          violated these Terms of Use, we encourage you to contact Teledental by
          sending an email to SIGNUP@Teledental.com and contact@Teledental.com
          or info@Teledental.com that identifies the applicable posting and the
          screen identity of the person who made the posting. Teledental cannot
          guarantee that any action will be taken as a result of your email. We
          can get thousands of requests per day, hence, we at times have a
          backlog. If do not get a reply, please try again IN the following
          day(s).
        </p>
        <p>
          <strong>
            OUR GOAL IS TO HELP PEOPLE WITH THEIR DENTAL CARE. USERS WHO MISUSE,
            OR SPAM MAY BE REFUSED SERVICE.
          </strong>
        </p>
        <p>
          <strong>
            <i>All rights not expressly granted herein are hereby reserved.</i>
          </strong>
        </p>
        <p>
          Also, whenever you voluntarily disclose anyone’s personal information
          on publicly-viewable web pages, that information can be collected and
          used by others. For example, if you post your email address, you may
          receive unsolicited messages. We cannot control who reads your posting
          or what other users may do with the information that you voluntarily
          post, so we encourage you to exercise discretion and caution with
          respect to information you choose to disclose through these
          interactive features. When an individual chooses to post information
          that will be publicly disclosed, he or she is responsible for all
          legal consequences. We are not responsible under any data protection
          laws for information that you voluntarily post on a site that can be
          accessed by others.
        </p>
        <p>
          If you believe that we have violated your privacy rights, you should
          contact us at the mailing address or e-mail address provided below.
        </p>
        <p>
          {" "}
          <strong>Applicable Laws</strong>{" "}
        </p>
        <p>
          We are subject to the relevant state laws and regulations governing
          the use and disclosure of health information created or received. We
          are also required to, among other things, apply reasonable and
          appropriate measures to safeguard the confidentiality, integrity, and
          availability of individually identifiable health information we store
          and process.
        </p>
        <p>
          {" "}
          <strong>Collection of non-dental Information</strong>{" "}
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Cookies. Our Application may use cookies and other technologies to
            collect information. Cookies are small bits of information that our
            websites place on the hard drive of your computer. We may use
            cookies to facilitate your login processes; allow you to personalize
            and store your settings; collect usage information; determine our
            total audience size and traffic, and help us improve our sites by
            measuring which areas are of greatest interest to users.
          </li>
          <li>
            Tracking and/or Analytics Services. We may use mobile application
            tracking and/or analytics services. These services may record unique
            mobile gestures such as tap, double-tap, zoom, pinch, scroll, swipe,
            and tilt but do not collect personally identifiable information that
            you do not voluntarily enter in the Application. These services do
            not track your browsing habits across mobile applications that do
            not use the same services. We are using the information collected by
            these services to understand user behavior and optimize the site
            performance.
          </li>
          <li>
            Web Beacons. We may also use web beacons (invisible images often
            referred to as pixel tags or clear GIFs) in order to recognize users
            and assess traffic patterns, and we may include web beacons and
            cookies in our email messages in order to count how many e-mail
            messages have been opened.
          </li>
          <li>
            Non-Personally Identifiable Information. We also collect
            Non-Personally Identifiable Information that is not Health
            information or Dental information in the form of statistics and
            information regarding the Application user’s statistics and metrics
            obtained from third-party devices (for example, steps, distance,
            calories burned, GPS coordinates, bat speed, hand speed, swing time,
            etc.), which may be combined with personal information you submit
            through the Application and/or Services so that you can fully enjoy
            the benefits of the Application’s tracking, monitoring, and
            diagnostic tools. We may also request the following optional
            information as part of your profile so that you can fully enjoy the
            features and functions of our Services: your weight, height, and
            gender.
          </li>
          <li>
            Mobile Device Information. Your use of the Application may also
            include the collection of information from your mobile device. For
            example, the Application may request your permission to collect
            location data and/or may request access to multimedia (photos or
            videos) stored on your mobile device. You have the option of
            declining collection of geolocation data, but this may limit your
            ability to participate in certain activities through the
            Application. If you do not wish for your location data to be shared
            with Teledental, please respond accordingly when prompted on your
            mobile device, or visit your mobile device settings. Multimedia will
            only be collected from your device if you affirmatively select it to
            upload to the application (i.e. you choose an image or video to
            store within the Application). Multimedia will not be shared with
            other Application users (except your profile photo, to the extent
            such feature is offered, which will appear in your user profile).
          </li>
        </ul>
        <p>TeleMedicine and TeleDental Dental Video Consultation:</p>
        <p>
          I understand that my health care provider wishes me to engage in a
          telemedicine consultation. 2. My health care provider has explained to
          me how the video conferencing technology will be used to affect such a
          consultation will not be the same as a direct patient/health care
          provider visit due to the fact that I will not be in the same room as
          my health care provider. 3. I understand there are potential risks to
          this technology, including interruptions, unauthorized access and
          technical difficulties. I understand that my healthcare provider or I
          can discontinue the telemedicine consult/visit if it is felt that the
          videoconferencing connections are not adequate for the situation. 4. I
          understand that my healthcare information may be shared with other
          individuals for scheduling and billing purposes. Others may also be
          present during the consultation other than my health care provider and
          consulting health care provider in order to operate the video
          equipment. The above-mentioned people will all maintain the
          confidentiality of the information obtained. I further understand that
          I will be informed of their presence in the consultation and thus will
          have the right to request the following: (1) omit specific details of
          my medical history/physical examination that are personally sensitive
          to me; (2) ask non-medical personnel to leave the telemedicine
          examination room: and or (3) terminate the consultation at any time.
          5. I have had the alternatives to a telemedicine consultation
          explained to me, and in choosing to participate in a
        </p>
        <p>
          telemedicine consultation. I understand that some parts of the exam
          involving physical tests may be conducted by individuals at my
          location at the direction of the consulting health care provider. 6.
          In an emergent consultation, I understand that the responsibility of
          the telemedicine consulting specialist is to advise my local
          practitioner and that the specialist’s responsibility will conclude
          upon the termination of the video conference connection. 7. I
          understand that billing will occur from both my practitioner and as a
          facility fee from the site from which I am presented. 8. I have had a
          direct conversation with my doctor, during which I had the opportunity
          to ask questions in regard to this procedure. My questions have been
          answered and the risks, benefits and any practical alternatives have
          been discussed with me in a language in which I understand. Any person
          using our teledental or telemedicine video consulting, agrees to these
          terms.
        </p>
        <p>
          {" "}
          <strong>Collection of Personal Information</strong>{" "}
        </p>
        <p>
          Examples of personal information we collect and process may include
          but are not limited to:
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Name, age, email address, username, password, and other registration
            information.
          </li>
          <li>
            Health Information that you provide us, which may include
            information or records relating to your medical or health history,
            health status and laboratory testing results, diagnostic images, and
            other health-related information.
          </li>
          <li>
            Health information about you prepared by the health care provider(s)
            who provide the Services through the Site such as medical records,
            treatment, and examination notes, and other health-related
            information.
          </li>
          <li>Billing information that you provide us.</li>
          <li>
            Information about the computer or mobile device you are using, such
            as what Internet browser you use, the kind of computer or mobile
            device you use, and other information about how you use the Site.
          </li>
          <li>
            Other information you input into the Site or related services.
          </li>
        </ul>{" "}
        <p>
          {" "}
          <strong>How We Use Your Information.</strong>{" "}
        </p>
        <p>
          Subject to this Privacy Policy, the Terms of Use and applicable terms
          and conditions of third-party applications, all data transmitted
          through the Site is owned by us; provided, however, that PHI that you
          provide for the purpose of scheduling a Visit with a Dental Practice
          will be solely owned by the Dental Practice. To the extent we are
          precluded from owning data transmitted through the Application, you
          grant us a perpetual, worldwide, royalty-free license to use such data
          (with the exception of PHI) to the extent necessary to enable the use
          of the Site and Services. Generally, we may use information in the
          following ways:
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            For the purposes for which you specifically provided it including,
            without limitation, to enable us to process your registration,
            provide the Services or other requests.
          </li>
          <li>To transmit a request for a Visit per your request.</li>
          <li>
            To send you information about your relationship or transactions with
            us.
          </li>
          <li>
            To notify you about our products, services, and special offers,
            except that we will not use PHI for marketing purposes without your
            prior written consent for yourself or your minor child.
          </li>
          <li>
            To otherwise contact you with information that we believe will be of
            interest to you.
          </li>
          <li>
            To enhance or develop features, products, and services. To allow us
            to personalize the content that you and others see on the
            Application.
          </li>
          <li>
            To provide advertisers and other third parties with aggregate
            information about Application users and Application usage patterns.
          </li>
          <li>
            To allow other select companies to send you promotional materials
            about their products and services, provided that no PHI will be used
            for this purpose without your prior written consent.
          </li>
          <li>
            For research purposes, for marketing and promotional purposes, and
            to develop new learning tools and solutions and we may share such
            information with third parties, including researchers and/or
            advertisers, on an aggregate and anonymous basis. We use IP
            addresses to analyze trends, administer the Application, track a
            visitor’s movement, and gather demographic information for
            aggregate, non-personally identifiable use.
          </li>
        </ul>{" "}
        <p>
          You acknowledge that unless you request in writing otherwise, we, in
          our sole discretion, have the right but not the obligation to store
          any information, whether Personally Identifiable Information or
          otherwise, perpetually, to the extent permitted by law. If you wish
          for any information about you to be removed from our database, please
          contact us through the contact information provided below in the “How
          to Contact Us” section of this Privacy Policy.
        </p>
        <p>
          {" "}
          <strong>Sharing Your Information.</strong>
        </p>
        <p>
          Except as otherwise described in this Privacy Policy, or if we inform
          you otherwise at the time of data collection and receive your consent
          where required, we will not sell, trade, or share your information
          with third parties.
        </p>
        <p>We may share your information as follows:</p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Visits: We will share your information, in your direction, to
            transmit a request for a Visit with a Dental Practice. Any PHI that
            we collect and save from you will be kept private and secure, as
            required by law.
          </li>
          <li>
            With Affiliates: We may share your information with affiliated
            companies and businesses, provided that your PHI will not be shared
            for any marketing purposes without your prior written consent, in
            accordance with applicable law.
          </li>
          <li>
            With Service Associates: We may use other companies to perform
            services including, without limitation, facilitating some aspects of
            our Application such as processing credit card transactions sending
            emails, fulfilling purchase requests, and data analysis on our
            behalf. These other companies may be supplied with or have access to
            your information solely for providing these services to you on our
            behalf. Such service shall be bound by appropriate confidentiality
            and security obligations, which may include, as applicable, business
            associate contract obligations.
          </li>
          <li>
            With Business Partners: When you make purchases or engage in
            promotions offered through our Site, we may share PII, but not your
            PHI, with the businesses with which we partner to offer you those
            products, services, and promotions. When you accept a particular
            business partner’s offer, you authorize us to provide your
            information to that business partner.
          </li>
          <li>
            As required by law, which can include providing information as
            required by law, regulation, subpoena, court order, legal process,
            or government request.
          </li>
          <li>
            When we believe in good faith that disclosure is necessary to
            protect your safety or the safety of others, to protect our rights,
            to investigate fraud, or to respond to a government request.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution or other sale or transfer
            of some or all of Teledental’s assets, whether as a going concern or
            as part of bankruptcy, liquidation or similar proceeding, in which
            Personal Information maintained by the Site is among the assets
            transferred.
          </li>
        </ul>{" "}
        <br />
        <p>
          {" "}
          <strong>
            Accessing and/or Updating Your Information/ Deleting your
            Information
          </strong>
        </p>
        <p>
          You have the ability to review and update your personal information by
          visiting the account management section on the Application. You can
          also review and update your personal information by contacting us at
          the email address provided below in the “How to contact us” section of
          this Privacy Policy.
        </p>
        <p>
          Deletion of Information. You can close your online account by emailing
          us at "enter email address". If you close your account, we will no
          longer use your online account information or share it with third
          parties. We may, however, retain a copy of the information for
          archival purposes, and to avoid identity theft or fraud.{" "}
        </p>
        <p>
          {" "}
          <strong>Protection of Your Information.</strong>{" "}
        </p>
        <p>
          We use commercially reasonable administrative, technical, and physical
          measures to safeguard your information in our possession against loss,
          theft and unauthorized use, disclosure, or modification. We follow
          generally accepted industry standards to protect the information
          submitted to us, both during transmission and once we receive it. No
          method of transmission over the internet is 100% secure, however.
          Therefore, while we strive to make all reasonable efforts to use
          commercially acceptable means to protect your personal information, we
          cannot guarantee its absolute security. In the unlikely event of a
          data breach, you will be notified as soon as reasonably possible, in
          accordance with applicable law. Furthermore, we are not responsible
          for any breach of security or for any actions of any third parties
          that receive the information.
        </p>
        <p>
          <strong>Strict No Refund Policy</strong>
        </p>
        <p>
          We do not reimburse any individual or companies if a security breach
          as described in the Protection of Your Information does occur. For
          dentists and dental offices, we do NOT PAY ANY OF THE COSTS of any
          patients referred through us. We reserve all rights to dismiss or
          remove any dental office listed with us, as we see fit. From time to
          time, like any website or App, it is possible to get hacked or to have
          downtime. We do not reimburse for any downtime.
        </p>
        <p>
          <strong>Links to Third-Party Sites.</strong>
        </p>
        <p>
          Our Application or website may contain links to websites or
          applications operated and maintained by third parties, over which we
          have no control. Privacy policies for these third-party sites and
          applications may be different from our Privacy Policy. You access
          these third-party sites and applications at your own risk. You should
          always read the privacy policy of a linked site or applications before
          disclosing any personal information on such site and/or through such
          applications. Teledental is not responsible for the information you
          submit to third-parties.
        </p>
        <p>
          <strong>How to Contact Us.</strong>
        </p>
        <p>
          If you have any questions, comments, or concerns about our Privacy
          Policy, you may contact us at service@Teledental.com and by writing a
          letter to:
        </p>
        <p>17602 17th Street, 102-149, Tustin, CA 92780</p>
        <p>
          We do not guarantee a response, as we can be inundated with emails,
          offerings, and questions.
        </p>
        <p>THE LAST UPDATE WAS 12/10/2019.</p>
        <p>
          We occasionally update our Privacy Policy. We post the date our Policy
          was last updated at the top of the Privacy Policy and provide
          additional notice of significant updates. Please check the Privacy
          Policy periodically for changes.
        </p>
        <p>
          The information you share with us allows us to provide you with
          superior customer service. The purpose of this Privacy Policy is to
          tell you how we collect, use, share, and protect your personal
          information.
        </p>
        <p>
          <strong>Information We Collect</strong>
        </p>
        <p>
          This Policy covers how and why we collect, use, share, and protect
          your personal information on our website and mobile app.
        </p>
        <p>
          Personal information is information that identifies you or reasonably
          can be linked to information that identifies you. For example, when
          you register for an account, we collect personal information, such as
          name, address, email address, phone number, and payment information.
        </p>
        <p>
          We collect personal information in two ways: (1) we receive it from
          you, either because you share it with us or because of your computer
          or mobile devices shares it with us; or (2) we receive it from another
          source.
        </p>
        <p>
          Examples of personal information we may receive from you include but
          are not limited to:
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            Name, age, email address, username, password, and other registration
            information.
          </li>
          <li>
            Health information that you provide us, which may include
            information or records relating to your medical or health history,
            health status and laboratory testing results, diagnostic images, and
            other health-related information.
          </li>
          <li>Billing information that you provide to us.</li>
          <li>
            Information about the computer or mobile device you are using, such
            as what Internet browser you use, the kind of computer or mobile
            device you use, and other information about how you use the site or
            app.
          </li>
          <li>
            Information collected by cookies. Cookies are small bits of
            information that we place on the hard drive of your computer or
            mobile device We may use cookies to facilitate your login processes;
            allow you to personalize and store your settings; collect usage
            information; determine our total audience size and traffic, and help
            us improve our sites by measuring which areas are of greatest
            interest to users. 6. Information collected by web beacons. Web
            beacons are invisible images often referred to as pixel tags or
            clear GIFs. We use web beacons to recognize users and assess traffic
            patterns, and we may include web beacons and cookies in our email
            messages in order to count how many e-mail messages have been
            opened.
          </li>
          <li>
            Information collected from your mobile device. For example, we may
            request your permission to collect location data and/or may request
            access to multimedia (photos or videos) stored on your mobile
            device. You can refuse to share location data, but this may disable
            some of the mobile app’s features. We only collect multimedia
            information when you affirmatively upload it to the mobile app. With
            the exception of your user profile photo, we never share your
            multimedia with other users.
          </li>
        </ul>{" "}
        <p>
          Examples of information about you we may collect from other sources:
        </p>
        <p>
          1. Health information about you prepared by the health care
          provider(s) who provide services through the site such as medical
          records, treatment, and examination notes, and other health-related
          information. 2. Information collected by mobile application tracking
          and/or analytics services. These services may record unique mobile
          gestures such as tap, double-tap, zoom, pinch, scroll, swipe, and
          tilt. These services do not track your browsing habits across mobile
          applications that do not use the same services. We may use the
          information collected by these services to understand your preferences
          and optimize site performance. 3. We may also collect information from
          third-party devices (for example, steps, distance, calories burned,
          GPS coordinates, bat speed, hand speed, swing time, etc.), which we
          may combine with other personal information you share with us.
        </p>
        <p>
          <strong>How We Use Your Information</strong>
        </p>
        <p>
          We use your personal information to provide services to you, to
          optimize our products and services, to improve our site and mobile app
          features, and to generally improve your user experience.
        </p>
        <p>
          More specifically, we may use your information in the following ways:
        </p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            For the purposes for which you specifically provided it, such as to
            register your account, provide service, or respond to other
            requests.
          </li>
          <li> To transmit your request for a dental visit.</li>
          <li>
            To send you information about your relationship or transactions with
            us.
          </li>
          <li>
            To notify you about our products, services, special offers, or other
            information that we believe may be of interest to you. However, we
            will never use your personal health information for marketing
            purposes without your prior written consent.
          </li>
          <li> To enhance or develop features, products, and services.</li>
          <li>
            To allow us to personalize the content that you see on our website
            or mobile app.
          </li>
          <li>
            To provide advertisers and other third parties with aggregate
            information about you and your preferences.
          </li>
          <li>
            With your prior written consent, to allow other select companies to
            send you promotional materials about their products and services.
            However, will never use your personal health information without
            your prior written consent.
          </li>
          <li>
            For research purposes and to develop new learning tools and
            solutions.
          </li>
        </ul>{" "}
        <p>
          <strong>How We Share Your Information</strong>
        </p>
        <p>
          We will not sell your information to third parties, except in the
          event all or a part of our business is merged, sold or reorganized. We
          may share your information for certain limited purposes, including but
          not limited to providing services to you, enabling affiliates and
          partners to provide you with products, services, or promotions, when
          legally required, or with your prior written consent.
        </p>
        <p>We may share your information as follows:</p>
        <ul style={{ listStyle: "decimal" }}>
          <li>
            We will share your personal information, at your direction, to
            transmit a request for a visit with a dental practice. Any personal
            health information that we collect will be kept private and secure,
            as required by law.
          </li>
          <li>
            We may use other companies to perform necessary services, such as
            processing credit card transactions, sending emails, fulfilling
            purchase requests, and data analysis. We may share your information
            with these companies solely for providing these services to you on
            our behalf.
          </li>
          <li>
            We may share your information with affiliated companies and
            businesses. However, we will never share your personal health
            information unless we obtain your prior written consent.
          </li>
          <li>
            When you make purchases or engage in promotions offered through our
            website or mobile application, we may share personally identifiable
            information, but not your personal health information, with our
            partners to offer you those products, services, and promotions. When
            you accept a particular business partner’s offer, you authorize us
            to provide your information to that business partner.
          </li>
          <li>
            We may share your information with researchers and/or advertisers,
            on an aggregate and anonymous basis. We use IP addresses to analyze
            trends, administer the website and mobile application, track a
            visitor’s movement, and gather demographic information for
            aggregate, non-personally identifiable use.
          </li>
          <li>
            As required by law, which can include providing information as
            required by law, regulation, subpoena, court order, legal process,
            or government request.
          </li>
          <li>
            When we believe in good faith that disclosure is necessary to
            protect your safety or the safety of others, to protect our rights,
            to investigate fraud, or to respond to a government request.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution or other sale or transfer
            of some or all of our assets, whether as a going concern or as part
            of bankruptcy, liquidation or similar proceeding, in which personal
            information is among the assets transferred.
          </li>
        </ul>
        <p>
          <strong>
            – FIND A DENTIST, ANSWERING YOUR DENTAL QUESTIONS, and LOCAL
            TELEDENTAL MARKETING ONLINE with us @ TELEDENTAL.COM.
          </strong>
        </p>
        <p>
          <strong>Your Rights</strong>
        </p>
        <p>
          You may access, update, or close your online account and request that
          we delete your data.
        </p>
        <p>
          You can access and update your personal information by visiting the
          account management section of the website.
        </p>
        <p>
          You can also review and update your personal information, or make a
          request to close your account and/or delete your data, by emailing us
          at service@Teledental.com or by writing a letter to:
        </p>
        <p>17602 17th Street, 102-149, Tustin, CA 92780</p>
        <p>
          If you close your account, we will no longer use your online account
          information or share it with third parties. We may, however, retain a
          copy of the information, whether personally identifiable information
          or otherwise, for fraud prevention or legal compliance.
        </p>
        <p>
          We do not sell your information, except in the limited circumstance
          listed above.
        </p>
        <p>
          <strong>California Consumer Privacy Act (CCPA)</strong>
        </p>
        <p>
          In addition to the rights described in the Your Rights section,
          California residents may request, up to two times in a 12-month
          period, the following information about the personal information we
          have collected about you during the past 12 months:
        </p>
        <ul>
          <li>
            the categories and specific pieces of personal information we have
            collected about you;
          </li>
          <li>
            the categories of sources from which we collected the personal
            information;
          </li>
          <li>
            the business or commercial purpose for which we collected or sold
            the personal information;
          </li>
          <li>
            the categories of third parties with whom we shared the personal
            information; and
          </li>
          <li>
            the categories of personal information about you that we sold or
            disclosed for a business purpose, and the categories of third
            parties to whom we sold or disclosed that information for a business
            purpose.
          </li>
        </ul>
        <p>
          We do not sell your information, except in the limited circumstance
          listed above. Nonetheless, California residents may exercise the right
          to <u>opt-out</u> of the sale of your personal information. To
          exercise your opt-out right, please email us at service@Teledental.com
          or by write a letter to:
        </p>
        <ul>
          <li>17602 17th Street, 102-149, Tustin, CA 92780</li>
        </ul>
        <p>
          Finally, California residents have the right against discrimination
          because you exercised the rights available to you and the right to
          protection against waiver of rights.
        </p>
        <p>CALIFORNIA PRIVACY RIGHTS</p>
        <p>
          California's "Shine the Light" law (Civil Code Section § 1798.83)
          PERMITS California residents to request certain information from us.
          Currently we are not selling third party marketing material of our
          users data. Can contact us at service@Teledental.com if want further
          information. This guideline is for larger companies that sell data. We
          have to date, not been selling data. This may change in the future.
        </p>
        <p>
          <strong>EU General Data Protection Regulation</strong>
        </p>
        <p>
          In addition to the rights described in the Your Rights section, if you
          live in the European Union., you may have additional personal data
          rights under the EU General Data Protection Regulation ("GDPR"),
          including the right to access the personal information we have about
          you, review or correct your information, request that we delete your
          data, request that we restrict or stop processing your data, or
          request a copy of your information. To exercise any of these rights,
          please email us at service@Teledental.com or by write a letter to:
        </p>
        <p>17602 17th Street, 102-149, Tustin, CA 92780</p>
        <p>
          <strong>How We Protect Your Information</strong>
        </p>
        <p>
          We use commercially reasonable administrative, technical, and physical
          measures to safeguard your information in our possession against loss,
          theft and unauthorized use, disclosure, or modification. We follow
          generally accepted industry standards to protect the information
          submitted to us, both during transmission and once we receive it. No
          method of transmission over the internet is 100% secure, however.
          Therefore, while we strive to make all reasonable efforts to use
          commercially acceptable means to protect your personal information, we
          cannot guarantee its absolute security. In the unlikely event of a
          data breach, you will be notified as soon as reasonably possible, in
          accordance with applicable law. Furthermore, we are not responsible
          for any breach of security or for any actions of any third parties
          that receive the information.
        </p>
        <p>
          <strong>Links to Third-Party Sites</strong>
        </p>
        <p>
          Our website or mobile app may contain links to third-party websites or
          applications, over which we have no control. Privacy policies for
          these third-party sites and applications may be different from our
          Privacy Policy. You access these third-party sites and applications at
          your own risk. You should always read the privacy policy of a linked
          site or application before disclosing any personal information. We are
          not responsible for the information you submit to third-parties.
        </p>
        <p>
          <strong>Lodge a Complaint</strong>
        </p>
        <p>
          In the event you consider our processing of your personal information
          to be non-compliant with the applicable data protection laws, you may
          lodge a complaint directly with us, using the contact information
          below. You may also lodge your complaint with the applicable data
          protection authority. The name and contact details of the Data
          Protection Authorities in the European Union can be found here.
        </p>
        <p>
          <strong>How to Contact Us</strong>
        </p>
        {/* <p>
          If you have any questions, comments, or concerns about our Privacy
          Policy, you may contact us at service@teledental.com or by writing a
          letter to:
        </p> */}
        {/* <p>17602 17th Street, 102-149, Tustin, CA 92780</p> */}
        <p>
          f you have any questions, comments, or concerns about our Privacy
          Policy, you may contact us at
          <br /> <Link to="/">service@teledental.com</Link>
        </p>
        {/* <p>
          <strong>Effective Date.</strong>
        </p>
        <p>This Privacy Policy is effective as of May 30, 2020.</p>
        <p>Privacy Policy</p>
        <p>Terms & Conditions.</p>
        <p>© 2020, Teledental. All Rights Reserved.</p> */}
      </div>
    </>
  );
}
export default PrivacyPolicy;
