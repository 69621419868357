import React from "react";
import Header from "../Commons/Header";
import { Link } from "react-router-dom";

const HowItWorks = () => {
  return (
    <>
      <Header />
      <section
        className="container contact-us-wrapper signup-page-wrapper pt-96 px-3"
        style={{ maxWidth: "900px" }}
      >
        <h2 className="text-center">HOW IT WORKS</h2>
        <p>
          <strong>
            Follow these steps to get started on your Teledental consultation
            today!
          </strong>
        </p>
        <ol>
          <li>Start by going to the patient sign-up page on Teledental.</li>
          <li> Fill out the form with the information requested.</li>
          <li>Book an appointment with a Teledental Dentist.</li>
          <li>Make payment or insurance (if accepted).</li>
        </ol>
        <p>
          Start Teledental patient sign-up{" "}
          <a href="https://teledental.com/patient-signup" target="_blank">
            https://teledental.com/patient-signup
          </a>
        </p>
        <p>
          Welcome to Teledental. Visit us at Teledental.com or Teledental app on
          IOS and Android.
        </p>
        <h3>
          Teledental is the next generation of virtual dental care services that
          connects live dentists to patients.
        </h3>
        <p>
          Teledental Consultation by appointment - From anywhere, at your
          convenience.
        </p>

        <p>
          Get started by going to{" "}
          <a href="https://teledental.com/patient-signup" target="_blank">
            https://teledental.com/patient-signup
          </a>
        </p>
        <p>
          FAQ Teledental Information visit{" "}
          <a href="https://teledental.com/faqs-question">
            https://teledental.com/faqs-question
          </a>
        </p>
        <p>
          Do you have a Virtual Cosmetic Dental Care Question, Live Toothache
          Pain Inquiry, Need More Virtual Dental Implant Information Online -can
          ask us your local dentistry questions at Teledental.
        </p>
        <p>
          Local Dental Question Discussion, Live Dentists and Virtual Dentistry
          Consulting - Teledental is here for you!
        </p>
        <p>
          Virtual Dental Care Messaging with a Live Dentist - Teledental is
          helping people get better dental insight into their dental inquiries.
          Many people are seeking online dental care information for their
          dental concerns.
        </p>
        <p>Your oral health is our priority!</p>
        <p>
          Live Tele Dental Consult | Virtual Dentists | Live Dentist
          Consultation | Teledentist Dental Help Information | Best
          Teledentistry Consulting{" "}
          <a href="https://teledental.com/patient-signup" target="_blank">
            https://teledental.com/patient-signup
          </a>
        </p>
        <h3>
          Teledental Dental Care Consulting - Live Virtual Dentistry Consult
          24/7
        </h3>
        <p>
          Would you love a live virtual dentistry consultation instead? Don't
          worry; we've got you covered! We understand that you may be busy with
          work or other activities, which is why we offer live video dental
          consultations and services with qualified dentists. Chat virtually
          with dentists 24/7, from anywhere!
        </p>
      </section>
    </>
  );
};

export default HowItWorks;
